export default {
    STORE_ATTRIBUTE_OPTION(state, data) {
        state.attributeOptions = data;
    },

    PUT_ATTRIBUTE_OPTION(state, data) {
        const index = state.attributeOptions.findIndex(o => o.id === data.id);

        if (index >= 0) {
            state.attributeOptions.splice(index, 1, data);
        } else {
            state.attributeOptions.unshift(data);
        }
    },

    RESET_ATTRIBUTE_OPTION(state, attributeId) {
        state.attributeOptions = state.attributeOptions.filter(o => o.attribute_id !== attributeId);
    },

    DESTROY_ATTRIBUTE_OPTION(state, id) {
        const index = state.attributeOptions.findIndex(o => o.id === id);

        if (index >= 0) {
            state.attributeOptions.splice(index, 1);
        }
    },

    SAVE_ATTRIBUTE_OPTION_AFTER_LOGIN(state, data) {
        state.saveAttributionOptionAfterLogin = data;
    },
};
