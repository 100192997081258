export const state = () => ({
    authOptions: {
        sitePosition: 'login_signup_popup',
        canLoginLater: false,
        hideForgotPassword: false,
        registerWithPassword: true,
        registerButtonLabel: 'Sign up with email',
        loginButtonLabel: 'Login with email',
    },
    isEmailValidating: false,
    emailDebounce: {
        is_validated: false,
        is_invalid: true,
        send_transactional: false,
        did_you_mean: '',
        is_forced: false,
    },
});
