export default {
    storeSavedJob(context, data) {
        const url = 'v1/me/saved-jobs';
        return this.$axios.$post(url, data);
    },

    getSavedJob({ commit, state }, query) {
        let url = 'v1/me/saved-jobs';
        if (query) {
            url += `?query=${query}`;
        }
        if (!state.savedJobs.length) {
            return this.$axios.$get(url).then((response) => {
                commit('STORE_SAVED_JOB', response.data);
                return response;
            });
        }
    },

    destroySavedJob(context, jobUuid) {
        const url = `v1/me/saved-jobs/${jobUuid}`;
        return this.$axios.$delete(url);
    },
};
