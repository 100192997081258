
    import {
        faArrowLeft as fasArrowLeft,
    } from '@fortawesome/pro-solid-svg-icons/faArrowLeft';
    import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

    export default {
        name: 'BackButton',

        components: {
            FontAwesomeIcon,
        },

        props: {
            label: {
                type: String,
                default: 'Go back',
            },
            buttonClass: {
                type: String,
                default: '',
            },
        },

        data() {
            return {
                icons: {
                    fasArrowLeft,
                },
            };
        },
    };
