export default {
    getOccupationSuggestions(context, data) {
        let url = 'v1/occupation-suggestions';
        if (typeof data === 'object') {
            const query = Object.keys(data).map(key => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`).join('&');
            if (query) {
                url += `?${query}`;
            }
        }

        return this.$axios.$get(url);
    },

    searchOccupations(context, data) {
        let url = 'v1/occupation-search';
        if (typeof data === 'object') {
            const query = Object.keys(data).map(key => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`).join('&');
            if (query) {
                url += `?${query}`;
            }
        }

        return this.$axios.$get(url);
    },
};
