export default function(req, res, next) {
    // find the redirect if it exists where the from === the requested url
    const redirects = [
        {
            from: '/discovery',
            to: '/discover',
        },
        {
            from: '/onboarding',
            to: '/update-profile',
        },
        {
            from: '/account',
            to: '/profile',
        },
        {
            from: '/dashboard',
            to: '/discover',
        },
        {
            from: '/dashboard/saved-searches',
            to: '/settings#notifications',
        },
        {
            from: '/account/email-alerts',
            to: '/settings#notifications',
        },
        {
            from: '/account/my-resumes',
            to: '/profile#resumes',
        },
        {
            from: '/register',
            to: '/auth',
        },
        {
            from: '/signup',
            to: '/auth',
        },
        {
            from: '/login',
            to: '/auth',
        },
        {
            from: '/signin',
            to: '/auth',
        },
    ];
    let reqUrl = req.url;
    let jobUuid = null;
    const vars = reqUrl.substring(reqUrl.indexOf('?') + 1).split('&');
    for (let i = 0; i < vars.length; i++) {
        const pair = vars[i].split('=');
        if (decodeURIComponent(pair[0]) === 'jobview') {
            jobUuid = decodeURIComponent(pair[1]);
        }
    }

    // To prevent doubled '/' of the base route
    const baseRoute = process.env.BASE_ROUTE === '/jobs'
        ? process.env.BASE_ROUTE
        : '';

    // redirect jobviews on serverside
    if (jobUuid) {
        reqUrl = `${baseRoute}/jobview/detail/${jobUuid}`;
        res.writeHead(301, { Location: reqUrl });
        res.end();
    } else {
        if (reqUrl?.includes('?')) {
            reqUrl = reqUrl.substring(0, reqUrl.indexOf('?'));
        }
        const redirectUrl = redirects.find(r => r.from === reqUrl);

        if (redirectUrl) {
            res.writeHead(301, { Location: redirectUrl.to });
            res.end();
        } else {
            next();
        }
    }
}
