export default {
    searchAttributes(context, { code, keyword }) {
        const url = `v1/search-attribute-options?code=${code}&keyword=${keyword}`;
        return this.$axios.$get(url);
    },

    async getSearchFilterAttributes() {
        let data = null;
        const url = 'v1/search-filter-attributes';
        if (process.server) {
            const cacheManager = require('cache-manager');
            const fsStore = require('cache-manager-fs-hash');
            const diskCache = cacheManager.caching({
                store: fsStore,
                options: {
                    path: 'diskcache', // path for cached files
                    ttl: 60 * 60, // time to life in seconds
                    subdirs: true, // create subdirectories to reduce the
                    // files in a single dir (default: false)
                },
            });
            data = await diskCache.get(url);
            if (data) { // get data from cache if exists
                return data;
            } else { // else get data from API
                return this.$axios.$get(url)
                    .then(async(response) => {
                        const data = response.data;
                        await diskCache.set(url, data);
                        return data;
                    });
            }
        } else { // else get data from API
            return this.$axios.$get(url)
                .then((response) => {
                    return response.data;
                });
        }
    },

    getEducationLevel({ dispatch }) {
        return dispatch('getAttributes', { code: 'education_level' });
    },

    getAvailability({ dispatch }) {
        return dispatch('getAttributes', { code: 'notice_period' });
    },

    getJobType({ dispatch }) {
        return dispatch('getAttributes', { code: 'job_type' });
    },

    getJobMode({ dispatch }) {
        return dispatch('getAttributes', { code: 'job_mode' });
    },

    getSector({ dispatch }) {
        return dispatch('getAttributes', { code: 'sector' });
    },

    getCompanySize({ dispatch }) {
        return dispatch('getAttributes', { code: 'company_size' });
    },

    getContractType({ dispatch }) {
        return dispatch('getAttributes', { code: 'contract_type' });
    },

    getIndustry({ dispatch }) {
        return dispatch('getAttributes', { code: 'industry' });
    },

    getWorkAuthorisation({ dispatch }) {
        return dispatch('getAttributes', { code: 'work_authorisation' });
    },

    getCareerLevel({ dispatch }) {
        return dispatch('getAttributes', { code: 'career_level' });
    },

    getAttributes(context, { code, keyword }) {
        let url = `v1/attributes/options?code=${code}`;
        if (keyword) {
            url += `&keyword=${keyword}`;
        }
        return this.$axios.$get(url);
    },

    getAttributesByCodes(context, { code }) {
        const url = `v1/attributes?code=${code}`;
        return this.$axios.$get(url);
    },
};
