export default {
    storeCoverLetter(context, data) {
        const url = 'v1/me/cover-letters';
        return this.$axios.$post(url, data);
    },

    getCoverLetter() {
        const url = 'v1/me/cover-letters';
        return this.$axios.$get(url);
    },

    updateCoverLetter(context, { id, data }) {
        const url = `v1/me/cover-letters/${id}`;
        return this.$axios.$put(url, data);
    },

    destroyCoverLetter(context, id) {
        const url = `v1/me/cover-letters/${id}`;
        return this.$axios.$delete(url);
    },
};
