export default function(context) {
    if (process.server) {
        const userIpCountryCode = context.req.headers['cf-ipcountry'];
        const nav = context.req.headers['user-agent'];
        const device = context.app.$device;
        context.store.commit('eventTracking/SET', { key: 'browser_user_agent', value: nav });
        context.store.commit('eventTracking/SET', { key: 'user_ip_country_code', value: userIpCountryCode });
        if (typeof nav === 'string') {
            if (nav.includes('C1_IOS_APP')) {
                context.store.commit('eventTracking/SET', { key: 'app_id', value: 'ios' });
                context.store.commit('eventTracking/SET', { key: 'platform', value: 'app' });
            } else if (nav.includes('C1_ANDROID_APP')) {
                context.store.commit('eventTracking/SET', { key: 'app_id', value: 'android' });
                context.store.commit('eventTracking/SET', { key: 'platform', value: 'app' });
            } else if (device.isMobile) {
                context.store.commit('eventTracking/SET', { key: 'platform', value: 'mob' });
            } else if (device.isMobileOrTablet) {
                context.store.commit('eventTracking/SET', { key: 'platform', value: 'tablet' });
            } else {
                context.store.commit('eventTracking/SET', { key: 'platform', value: 'web' });
            }
        } else {
            context.store.commit('eventTracking/SET', { key: 'platform', value: 'unknown' });
            context.store.commit('eventTracking/SET', { key: 'app_id', value: 'unknown' });
            context.store.commit('eventTracking/SET', { key: 'platform', value: 'unknown' });
        }
    }
}
