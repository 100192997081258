import attributeOption from '@/store/me/mutations/attributeOption';
import desiredOccupation from '@/store/me/mutations/desiredOccupation';
import education from '@/store/me/mutations/education';
import resumeQualityFeedback from '@/store/me/mutations/resumeQualityFeedback';
import resume from '@/store/me/mutations/resume';
import savedJob from '@/store/me/mutations/savedJob';
import appliedJob from '@/store/me/mutations/appliedJob';
import jobEvents from '@/store/me/mutations/jobEvents';
import savedSearch from '@/store/me/mutations/savedSearch';
import scoring from '@/store/me/mutations/scoring';
import user from '@/store/me/mutations/user';
import widgets from '@/store/me/mutations/widgets';
import workExperience from '@/store/me/mutations/workExperience';
import workLocation from '@/store/me/mutations/workLocation';
import discovery from '@/store/me/mutations/discovery';
import profileTask from '@/store/me/mutations/profileTask';

export default {
    ...attributeOption,
    ...desiredOccupation,
    ...education,
    ...resumeQualityFeedback,
    ...resume,
    ...savedJob,
    ...appliedJob,
    ...jobEvents,
    ...savedSearch,
    ...scoring,
    ...user,
    ...widgets,
    ...workExperience,
    ...workLocation,
    ...discovery,
    ...profileTask,
};
