import has from 'lodash/has';
import { slugify } from '@/utils/helpers';

export default {
    getSearchPayLoad(state) {
        return state.searchPayload;
    },

    getSearchFilterTags(state) {
        const attrLabel = {
            job_type: 'work type',
            skills: 'skill',
            sector: 'sector',
            contract_type: 'contract type',
            career_level: 'career level',
            perks: 'Perk',
            work_authorisation: 'work authorisation',
            education_level: 'education level',
            languages: 'language',
            job_mode: 'Work mode',
            equal_opportunity_tags: 'Diversity & inclusion',
            certifications: 'licence/certification',
        };
        const data = [];
        const searchFilters = state.searchPayload.search_filters;
        let handledSalary = false;
        if (searchFilters) {
            Object.keys(searchFilters).forEach((key) => {
                if (key === 'job_type' || key === 'skills' || key === 'sector' || key === 'job_mode' || key === 'contract_type' || key === 'career_level' ||
                    key === 'perks' || key === 'work_authorisation' || key === 'education_level' || key === 'languages' || key === 'certifications') {
                    if (searchFilters[key].length) {
                        searchFilters[key].forEach(function(item) {
                            data.push({ attribute_code: key, id: item.id, value: item.value, label: attrLabel[key] });
                        });
                    }
                } else if (key === 'ad_type') {
                    if (searchFilters[key].length) {
                        searchFilters[key].forEach(function(item) {
                            data.push({ attribute_code: key, id: item.id, value: item.name, label: 'ad type' });
                        });
                    }
                } else if (key === 'industry') {
                    if (searchFilters[key].length) {
                        searchFilters[key].forEach(function(item) {
                            data.push({ attribute_code: key, id: item.id, value: item.value, label: 'industry' });
                        });
                    }
                } else if (key === 'company_size') {
                    if (searchFilters[key].length) {
                        searchFilters[key].forEach(function(item) {
                            data.push({ attribute_code: key, id: item.id, value: item.value, label: 'company size' });
                        });
                    }
                } else if (key === 'keywords') {
                    if (searchFilters[key].length) {
                        searchFilters[key].forEach(function(item, index) {
                            data.push({ attribute_code: key, id: index, value: item, label: 'keyword' });
                        });
                    }
                } else if ((key === 'pay_max' || key === 'pay_min') && !handledSalary) {
                    handledSalary = true;
                    if (searchFilters.pay_max && searchFilters.pay_min && searchFilters.pay_max.label && searchFilters.pay_min.label) {
                        data.push({ attribute_code: key, id: searchFilters[key].id, value: `${searchFilters.pay_min.label} to ${searchFilters.pay_max.label}`, label: 'salary' });
                    } else if (searchFilters.pay_max && searchFilters.pay_max.label) {
                        data.push({ attribute_code: key, id: searchFilters.pay_max.id, value: `< ${searchFilters.pay_max.label}`, label: 'salary' });
                    } else if (searchFilters.pay_min && searchFilters.pay_min.label) {
                        data.push({ attribute_code: key, id: searchFilters.pay_min.id, value: `> ${searchFilters.pay_min.label}`, label: 'salary' });
                    }
                } else if (key === 'posted_within_days') {
                    if (searchFilters[key] && searchFilters[key].value) {
                        if (searchFilters[key].days !== 0) {
                            data.push({ attribute_code: key, id: searchFilters[key].id, value: searchFilters[key].value, label: 'posted within' });
                        }
                    }
                } else if (key === 'brands') {
                    if (searchFilters[key].length) {
                        searchFilters[key].forEach(function(item, index) {
                            data.push({ attribute_code: key, id: item.id, value: item.name, label: 'company' });
                        });
                    }
                } else if (key === 'equal_opportunity_tags') {
                    if (searchFilters[key].length) {
                        const eeoIds = searchFilters[key].map(i => parseInt(i));
                        eeoIds.forEach(function(id) {
                            const eeoTag = state.availableEeoTags.find(i => i.id === id);
                            if (eeoTag) {
                                data.push({ attribute_code: key, id, value: eeoTag.label, label: attrLabel[key] });
                            }
                        });
                    }
                }
                // else if (key === 'categories') {
                //     if (searchFilters[key].length) {
                //         searchFilters[key].forEach(function(item, index) {
                //             if (item.occupations && item.occupations.length > 1) {
                //                 item.occupations.forEach(function(item1, index1) {
                //                     data.push({ attribute_code: 'occupations', id: item1.id, value: item1.name, category_id: item1.category_id, label: 'occupation' });
                //                 });
                //             } else if (searchFilters[key].length > 1) {
                //                 data.push({ attribute_code: key, id: item.id, value: item.name, label: 'category' });
                //             }
                //         });
                //     }
                // }
            });
        }
        return data;
    },

    jobCount(state) {
        return has(state.searchPayload, 'search_results.job_count') ? state.searchPayload.search_results.job_count : 0;
    },

    companyName(state) {
        return state.brand.name || '';
    },

    companyAbout(state) {
        return state.brand.brand_content || '';
    },

    companyPrimaryColor(state) {
        return has(state.brand, 'primary_color') ? state.brand.primary_color : '';
    },

    companyLogoUrl(state) {
        return has(state.brand, 'brandLogo.url') ? state.brand.brandLogo.url : '';
    },

    companySquareLogoUrl(state) {
        return has(state.brand, 'brandSquareLogo.url') ? state.brand.brandSquareLogo.url : '';
    },

    companySize(state) {
        return state.brand.company_size || '';
    },

    companyEEO(state) {
        return state.brand.equal_opportunity_tags || [];
    },

    companyLink(state) {
        if (state.brand.slug) {
            return `/jobs/br_${state.brand.slug}`;
        }

        if (state.brand.name) {
            return `/jobs/br_${slugify(state.brand.name)}`;
        }

        return '';
    },

    companyMedias(state) {
        return has(state.brand, 'medias') ? state.brand.medias : [];
    },

    companyWebsite(state) {
        return state.brand.website || '';
    },

    companySector(state) {
        if (has(state, 'brand.attributes')) {
            const attributes = state.brand.attributes;
            const sector = attributes.find(o => o.slug === 'sector') || {};
            if (sector.id) {
                return sector.options[0].value;
            }
        }

        return '';
    },

    companyPerks(state) {
        if (state.brand.attributes) {
            const attributes = state.brand.attributes;
            const perks = attributes.find(o => o.slug === 'perks');
            return perks ? perks.options : [];
        } else {
            return [];
        }
    },

    companyIndustry(state) {
        if (state.brand.attributes) {
            const attributes = state.brand.attributes;
            const perks = attributes.find(o => o.slug === 'industry');
            return perks ? perks.options[0].value : '';
        } else {
            return '';
        }
    },

    companySlug(state) {
        return state.brand.slug || '';
    },

    companyHasPaidJobs(state) {
        if (!state.brand.id) {
            return false;
        } else if (state.brand && state.brand.has_paid_jobs) {
            return true;
        } else {
            return false;
        }
    },
};
