import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);

export function jobData(job) {
    if (!job.uuid) {
        return;
    }

    let jobSector = null;
    if (job.sector && job.sector.length) {
        jobSector = job.sector.map((item, index) => {
            return {
                id: parseInt(item, 10),
                value: job.sector_label[index],
            };
        });
    }

    let jobSkill = null;
    if (job.skills && job.skills.length) {
        jobSkill = job.skills.map((item, index) => {
            return {
                id: parseInt(item, 10),
                value: job.skills_details[index].value,
            };
        });
    }

    let jobIndustry = null;
    if (job.industry && job.industry.length) {
        jobIndustry = job.industry.map((item, index) => {
            return {
                id: parseInt(item, 10),
                value: job.industry_label[index],
            };
        });
    }

    let jobCertificate = null;
    if (job.certifications && job.certifications.length) {
        jobCertificate = job.certifications.map((item, index) => {
            return {
                id: parseInt(item, 10),
                value: job.certifications_label[index],
            };
        });
    }

    return {
        job_id: parseInt(job.id, 10) || '',
        job_uuid: job.uuid || '',

        job_category: job.category_label || '',
        job_category_id: parseInt(job.category_id, 10) || '',

        job_occupation: job.occupation_label || '',
        job_occupation_id: parseInt(job.occupation_id, 10) || '',
        job_occupation_list: job.occupations_list || '',

        job_title: job.job_title || '',
        job_njt_id: job.base_normalised_job_title_id || '',
        job_njt: job.base_normalised_job_title || '',

        job_type_id: parseInt(job.job_type_id, 10) || '',
        job_type: job.job_type_label || '',

        contract_type_id: parseInt(job.contract_type_id, 10) || '',
        contract_type: job.contract_type_label || '',

        job_mode_id: parseInt(job.job_mode_id, 10) || '',
        job_mode: job.job_mode_label || '',

        company_size_id: parseInt(job.company_size_id, 10) || '',
        company_size: job.company_size_label || '',

        sector: job.sector_label && job.sector_label.length ? job.sector_label.join(', ') : '',
        job_sector: jobSector,

        job_skills: jobSkill,

        job_certifications: jobCertificate,

        industry: job.industry_label && job.industry_label.length ? job.industry_label.join(', ') : '',
        job_industry: jobIndustry,

        job_source_code: job.source_code || '',
        job_channel_id: parseInt(job.channel_id, 10) || '',

        job_account_id: parseInt(job.account_id, 10) || '',
        job_account_name: job.account_name || '',

        job_brand_id: parseInt(job.brand_id, 10) || '',
        job_brand_name: job.company_name || '',

        job_bucket_code: job.bucket_code || '',
        job_campaign_id: parseInt(job.campaign_id, 10) || '',
        job_partner_id: parseInt(job.partner_id, 10) || '',

        job_apply_type_id: parseInt(job.apply_type_id, 10) || '',
        job_activates_at: job.activates_at ? dayjs.utc(job.activates_at).local().format('YYYY-MM-DD HH:mm:ss') : '',
        job_expires_at: job.expires_at ? dayjs.utc(job.expires_at).local().format('YYYY-MM-DD HH:mm:ss') : '',
        job_flow_enabled: job.flow_enabled ? 1 : 0,

        job_location: job.location_label || '',
        job_location_id: parseInt(job.location_id, 10) || '',
        job_location_type: job.location_type || '',
        j_loc_postcode: job.location_post_code || '',
        j_loc_suburb_id: parseInt(job.location_level_1, 10) || '',
        j_loc_area_id: parseInt(job.location_level_2, 10) || '',
        j_loc_region_id: parseInt(job.location_level_3, 10) || '',
        j_loc_state_id: parseInt(job.location_level_4, 10) || '',
        j_loc_country_id: parseInt(job.location_level_5, 10) || '',
        j_loc_country_name: job.location_level_5_label || '',
        j_loc_country_code: job.location_country_code || '',
        j_loc_state_code: job.location_state_code || '',
        j_loc_suburb_name: job.location_level_1_label || '',
        j_loc_area_name: job.location_level_2_label || '',
        j_loc_region_name: job.location_level_3_label || '',
        j_loc_state_name: job.location_level_4_label || '',

        job_pay_hidden: job.pay_hidden ? 1 : 0,
        job_pay_max: job.pay_max || '',
        job_pay_min: job.pay_min || '',
        job_pay_type: job.pay_type || '',
        job_pay_estimated_min: job.pay_chart_data && job.pay_chart_data.min_market_pay ? job.pay_chart_data.min_market_pay : '',
        job_pay_estimated_max: job.pay_chart_data && job.pay_chart_data.max_market_pay ? job.pay_chart_data.max_market_pay : '',

        job_product_id: parseInt(job.product_id, 10) || '',
        job_reference: job.source_reference || '',
        job_refreshed_at: job.refreshed_at ? dayjs.utc(job.refreshed_at).local().format('YYYY-MM-DD HH:mm:ss') : '',
        job_sponsored: job.is_sponsored ? 1 : 0,

        job_is_onsite_apply: job.is_onsite_apply ? 1 : 0,

        apply_questions_count: job.apply_questions_count,
        apply_questions_step_count: job.apply_questions_step_count,
    };
}
