export default {
    STORE_JOB_UUID(state, data) {
        state.jobUuid = data;
    },

    STORE_VERTICAL_SCROLL_POSITION(state, data) {
        state.currentVerticalScroll = data;
    },

    STORE_SECTION_TITLE(state, data) {
        state.sectionTitle = data;
    },
};
