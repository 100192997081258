export default {
    STORE_WORK_EXPERIENCE(state, data) {
        state.workExperience = data;
    },

    PUT_WORK_EXPERIENCE(state, data) {
        const index = state.workExperience.findIndex(o => o.id === data.id);

        if (index >= 0) {
            state.workExperience.splice(index, 1, data);
        } else {
            state.workExperience.unshift(data);
        }
    },

    DESTROY_WORK_EXPERIENCE(state, id) {
        const index = state.workExperience.findIndex(o => o.id === id);

        if (index >= 0) {
            state.workExperience.splice(index, 1);
        }
    },
};
