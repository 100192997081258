
    import { mapState, mapGetters } from 'vuex';
    import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
    import {
        faPencil as farPencil,
    } from '@fortawesome/pro-regular-svg-icons/faPencil';

    export default {
        name: 'SeekerAvatar',

        components: {
            FontAwesomeIcon,
        },

        props: {
            size: {
                type: Number,
                default: 32,
                validator: (value) => {
                    const sizes = [22, 32, 48, 80];
                    return sizes.includes(value);
                },
            },

            status: {
                type: String,
                default: '',
            },

            isStatic: {
                type: Boolean,
                default: false,
            },

            shadow: {
                type: Boolean,
                default: false,
            },

            editable: {
                type: Boolean,
                default: false,
            },

            glow: {
                type: Boolean,
                default: false,
            },

            noClickEvent: {
                type: Boolean,
                default: false,
            },
        },

        data() {
            return {
                icons: {
                    farPencil,
                },
            };
        },

        computed: {
            ...mapGetters('me', {
                $_meAvatar: 'avatar',
            }),

            ...mapState({
                $_me: state => state.me.user,
                $_profileScore: state => state.me.user.profile_completion,
            }),

            classList() {
                return [
                    'avatar position-relative',
                    this.getAvatarSize,
                    this.getAvatarStatus,
                    this.getCursorClass,
                    this.getShadowClass,
                    this.getEditableClass,
                ];
            },

            getEditableClass() {
                return this.editable ? 'cursor-pointer' : '';
            },

            getShadowClass() {
                return this.shadow ? 'shadow-sm' : '';
            },

            getAvatarSize() {
                return this.size ? `is-${this.size}` : '';
            },

            getAvatarStatus() {
                return this.status ? `is-${this.status}` : '';
            },

            getCursorClass() {
                return !this.isStatic ? 'cursor-pointer' : '';
            },

            nameInitials() {
                let initial = '';

                const firstName = this.$_me.first_name;
                const lastName = this.$_me.last_name;

                if (lastName) {
                    initial = lastName.substring(0, 1).toUpperCase();
                } else if (firstName) {
                    initial = firstName.substring(0, 1).toUpperCase();
                }

                return initial;
            },

            avatarVariant() {
                return this.$_profileScore !== 100 ? 'theme-light-800' : 'theme-c1-green-300';
            },
        },

        methods: {
            click() {
                this.$emit('click');
            },
        },
    };
