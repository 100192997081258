export default async({ app, store, $axios, redirect }, inject) => {
    let settingData = null;
    if (store.state.common.siteSettings === '') {
        const siteSettingsUrl = `${process.env.API_URL}/v1/domain/site-settings?domain=${app.$hostname}`;
    if (process.server) {
        const cacheManager = require('cache-manager');
        const fsStore = require('cache-manager-fs-hash');
        const diskCache = cacheManager.caching({
            store: fsStore,
            options: {
                path: 'diskcache', // path for cached files
                ttl: 60 * 60, // time to life in seconds
                subdirs: true, // create subdirectories to reduce the
                // files in a single dir (default: false)
            },
        });
        settingData = await diskCache.get(siteSettingsUrl);
        if (!settingData) { // else get data from API
            settingData = await $axios.$get(siteSettingsUrl)
                .then(async(response) => {
                    const data = response.data;
                    await diskCache.set(siteSettingsUrl, data);
                    return data;
                }).catch((e) => {
                    redirect('/error');
                });
        }
    } else {
        const settingResponse = await $axios.get(siteSettingsUrl);
        settingData = settingResponse.data.data;
    }
        store.commit('common/STORE_SITE_SETTINGS', settingData);
    } else {
        settingData = store.state.common.siteSettings;
    }
    // Set default locale
    const locale = settingData.find(o => o.setting_code === 'site_locale')?.value || 'AU';
    store.commit('SET_LANG', locale);

    const siteUrl = settingData[0]?.site_url || '';
    const baseRoute = process.env.BASE_ROUTE.replace('/', '');

    let fontUrls = '';
    if (process.env.APP_ENV === 'local') {
        fontUrls = {
            acm: 'http://localhost:3000/acm-fonts.css',
            careerone: 'http://localhost:3000/careerone-fonts.css',
        };
    } else {
        fontUrls = {
            acm: `${siteUrl}acm-fonts.css`,
            careerone: `${siteUrl}careerone-fonts.css`,
        };
    }

    const settings = {
        // Settings
        logo: settingData.find(o => o.setting_code === 'email_dark_logo')?.value || '',
        logoWhite: settingData.find(o => o.setting_code === 'email_white_logo')?.value || '',
        siteBanner: settingData.find(o => o.setting_code === 'site_banner')?.value || '',
        favicon: settingData.find(o => o.setting_code === 'favicon')?.value || '',
        hiringUrl: settingData.find(o => o.setting_code === 'hiring_url')?.value || '',
        contactUrl: settingData.find(o => o.setting_code === 'contact_url')?.value || '',
        jobSearchDefaultUrlQuery: settingData.find(o => o.setting_code === 'job_search_default_url')?.value || '/jobs/in-australia',
        appleClientId: settingData.find(o => o.setting_code === 'apple_client_id')?.value || '',
        gtmTag: settingData.find(o => o.setting_code === 'gtm_tag')?.value || 'GTM-MB79ZS',
        legalName: settingData.find(o => o.setting_code === 'legal_name')?.value || '',
        siteBannerUrl: settingData.find(o => o.setting_code === 'site_banner_url')?.value || '/',
        footer: settingData.find(o => o.setting_code === 'seeker_footer')?.value || '',
        jobCountText: settingData.find(o => o.setting_code === 'job_count_text')?.value || '',
        hideEducation: settingData.find(o => o.setting_code === 'hide_education')?.value || 'No',
        adsLayout: settingData.find(o => o.setting_code === 'ads_layout')?.value || '',
        // Site
        siteUrl: settingData[0]?.site_url || '',
        siteName: settingData[0]?.name || '',
        siteCode: settingData[0]?.site_code || '',
        platformCode: settingData[0]?.platform_code || '',
        fontUrl: fontUrls[settingData[0]?.platform_code || 'careerone'] || fontUrls.careerone,
    };
    try {
        if (settings.footer) {
            settings.footer = JSON.parse(settings.footer);
        }
    } catch (e) {
        settings.footer = '';
    }
    if (settings.platformCode !== 'careerone') {
        if (process.env.APP_ENV === 'local') {
            settings.siteUrl = `http://localhost:3000/${baseRoute}/`;
            settings.siteBannerUrl = `http://localhost:3000/${baseRoute}/`;
        }
        settings.jobSearchUrlPath = settings.siteUrl + settings.jobSearchDefaultUrlQuery.replace('/', '');
    } else {
        if (process.env.APP_ENV === 'local') {
            settings.siteUrl = 'http://localhost:3000/';
            settings.siteBannerUrl = 'http://localhost:3000/';
        }
        settings.jobSearchUrlPath = settings.siteUrl + 'jobs/in-australia';
    }
    Object.entries(settings).forEach(([name, setting]) => {
        inject(name, setting);
    });
};
