export default {
    getSubscriptions() {
        const url = 'v1/me/subscriptions';
        return this.$axios.$get(url);
    },

    storeSubscription(context, data) {
        const url = 'v1/me/subscriptions';
        return this.$axios.$post(url, data);
    },

    deactivateSubscription(context, id) {
        const url = `v1/me/subscriptions/${id}/deactivate`;
        return this.$axios.$put(url);
    },
};
