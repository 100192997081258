export const state = () => ({
    event: '',
    site: '',
    page_type: '',
    viewport_size: {},
    device_resolution: {},
    page_referral_element: '',
    platform: '',
    platform_code: '',
    app_id: '',
    current_page_url: '',
    referring_page_url: '',
    utm: {},
    browser_user_agent: '',
    timestamp: '',
    created_at: '',
    user_ip_country_code: '',
    seeker_email: '',
    seeker_id: '',
    seeker_hash: '',
    domain_session_id: '',
    guest_email: '',
    isDomainSessionAvailable: false,
});
