export default {
    getDesiredOccupations(context, data) {
        const url = 'v1/me/desired-occupations';
        return this.$axios.$get(url, data);
    },

    syncDesiredOccupation(context, data) {
        const url = 'v1/me/desired-occupations';
        return this.$axios.$post(url, data);
    },
};
