import Cookie from 'js-cookie';
import { getTokenFromReq } from '@/utils/helpers';

export default ({ req, redirect, store }) => {
    if (process.server) {
        const token = getTokenFromReq(req);

        if (token && store.state.me.user.id) {
            return redirect({ name: 'discover' });
        }
    }

    if (process.browser) {
        if (Cookie.get(process.env.COOKIE_TOKEN_NAME) && store.state.me.user.id) {
            return redirect({ name: 'discover' });
        }
    }
};
