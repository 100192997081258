
    import HtmlHeader from '@/components/Partials/HtmlHeader';

    export default {
        name: 'Redirect',

        mixins: [HtmlHeader],

        head() {
            const head = this.head;
            head.title = 'Redirecting ...';
            head.bodyAttrs = {
                class: 'job-redirect',
            };

            return head;
        },
    };
