export default (ctx, inject) => {
  // make lazy component loaders available for hydratableLoader
  // lets set all component options of components which were detected by nuxt/components including its loaders as prop of each component

  // now we are going to inject all the dynamic webpack imports to each component as single property with its own chunk
  const loaders = {
      LazyNuxtDynamic: () => import('../node_modules/@blokwise/dynamic/src/components/Dynamic.vue' /* webpackChunkName: "components/nuxt-dynamic" */).then(c => c.default || c),
      LazyCertificationPrompt: () => import('../components/Prompts/Components/CertificationPrompt.vue' /* webpackChunkName: "components/certification-prompt" */).then(c => c.default || c),
      LazyCoverLetterPrompt: () => import('../components/Prompts/Components/CoverLetterPrompt.vue' /* webpackChunkName: "components/cover-letter-prompt" */).then(c => c.default || c),
      LazyEducationPrompt: () => import('../components/Prompts/Components/EducationPrompt.vue' /* webpackChunkName: "components/education-prompt" */).then(c => c.default || c),
      LazyGetJobMatchesPromptLg: () => import('../components/Prompts/Components/GetJobMatchesPromptLg.vue' /* webpackChunkName: "components/get-job-matches-prompt-lg" */).then(c => c.default || c),
      LazyGetJobMatchesPromptMd: () => import('../components/Prompts/Components/GetJobMatchesPromptMd.vue' /* webpackChunkName: "components/get-job-matches-prompt-md" */).then(c => c.default || c),
      LazyGetJobMatchesPromptShort: () => import('../components/Prompts/Components/GetJobMatchesPromptShort.vue' /* webpackChunkName: "components/get-job-matches-prompt-short" */).then(c => c.default || c),
      LazyGetJobMatchesPromptSm: () => import('../components/Prompts/Components/GetJobMatchesPromptSm.vue' /* webpackChunkName: "components/get-job-matches-prompt-sm" */).then(c => c.default || c),
      LazyLoginPrompt: () => import('../components/Prompts/Components/LoginPrompt.vue' /* webpackChunkName: "components/login-prompt" */).then(c => c.default || c),
      LazyResumePrompt: () => import('../components/Prompts/Components/ResumePrompt.vue' /* webpackChunkName: "components/resume-prompt" */).then(c => c.default || c),
      LazyResumeUploadOptionPrompt: () => import('../components/Prompts/Components/ResumeUploadOptionPrompt.vue' /* webpackChunkName: "components/resume-upload-option-prompt" */).then(c => c.default || c),
      LazyTopResumePrompt: () => import('../components/Prompts/Components/TopResumePrompt.vue' /* webpackChunkName: "components/top-resume-prompt" */).then(c => c.default || c),
      LazyWorkExperiencePrompt: () => import('../components/Prompts/Components/WorkExperiencePrompt.vue' /* webpackChunkName: "components/work-experience-prompt" */).then(c => c.default || c),
      LazyWorkSkillsPrompt: () => import('../components/Prompts/Components/WorkSkillsPrompt.vue' /* webpackChunkName: "components/work-skills-prompt" */).then(c => c.default || c),
      LazyOnBoardingComplete: () => import('../components/Prompts/Components/OnBoardingPrompt/OnBoardingComplete.vue' /* webpackChunkName: "components/on-boarding-complete" */).then(c => c.default || c),
      LazyOnBoardingGetStarted: () => import('../components/Prompts/Components/OnBoardingPrompt/OnBoardingGetStarted.vue' /* webpackChunkName: "components/on-boarding-get-started" */).then(c => c.default || c),
      LazyOnBoardingOccupationPrompt: () => import('../components/Prompts/Components/OnBoardingPrompt/OnBoardingOccupationPrompt.vue' /* webpackChunkName: "components/on-boarding-occupation-prompt" */).then(c => c.default || c),
      LazyOnBoardingPrompt: () => import('../components/Prompts/Components/OnBoardingPrompt/OnBoardingPrompt.vue' /* webpackChunkName: "components/on-boarding-prompt" */).then(c => c.default || c),
      LazyOnBoardingSeekerDetailsPrompt: () => import('../components/Prompts/Components/OnBoardingPrompt/OnBoardingSeekerDetailsPrompt.vue' /* webpackChunkName: "components/on-boarding-seeker-details-prompt" */).then(c => c.default || c),
      LazyOnBoardingWorkExperiencePrompt: () => import('../components/Prompts/Components/OnBoardingPrompt/OnBoardingWorkExperiencePrompt.vue' /* webpackChunkName: "components/on-boarding-work-experience-prompt" */).then(c => c.default || c),
      LazyOnBoardingWorkLocationPrompt: () => import('../components/Prompts/Components/OnBoardingPrompt/OnBoardingWorkLocationPrompt.vue' /* webpackChunkName: "components/on-boarding-work-location-prompt" */).then(c => c.default || c),
      LazyOnBoardingWorkSkillsPrompt: () => import('../components/Prompts/Components/OnBoardingPrompt/OnBoardingWorkSkillsPrompt.vue' /* webpackChunkName: "components/on-boarding-work-skills-prompt" */).then(c => c.default || c)
  }

  const prefixes = ["nuxt"]

  inject("nuxtDynamic", { loaders, prefixes });
};
