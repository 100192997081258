import apply from '@/store/jobApplication/mutations/apply';
import applyForm from '@/store/jobApplication/mutations/applyForm';
import onboardingQuestions from '@/store/jobApplication/mutations/onboardingQuestions';
import customQuestions from '@/store/jobApplication/mutations/customQuestions';
import signinModal from '@/store/jobApplication/mutations/signinModal';

export default {
    ...apply,
    ...applyForm,
    ...onboardingQuestions,
    ...customQuestions,
    ...signinModal,
};
