import has from 'lodash/has';

export default {
    brandSector(state) {
        if (has(state, 'brand.attributes')) {
            const attributes = state.brand.attributes;
            const sector = attributes.find(o => o.slug === 'sector') || {};
            if (sector.id) {
                return sector.options[0].value;
            }
        }

        return '';
    },

    brandIndustry(state) {
        if (has(state, 'brand.attributes')) {
            const attributes = state.brand.attributes;
            const sector = attributes.find(o => o.slug === 'industry') || {};
            if (sector.id) {
                return sector.options[0].value;
            }
        }

        return '';
    },
};
