export default ({ req }, inject) => {
    let userAgent = '';
    if (process.server) {
        userAgent = req.headers['user-agent'];
    }

    if (process.browser) {
        userAgent = navigator.userAgent;
    }

    let isC1Android = false;
    let isC1Ios = false;
    let isApp = false;
    let isC1IosTablet = false;

    if (userAgent !== undefined) {
        isC1Android = userAgent.includes('C1_ANDROID_APP');
        isC1Ios = userAgent.includes('C1_IOS_APP');
        isApp = userAgent.includes('C1_ANDROID_APP') || userAgent.includes('C1_IOS_APP');
        isC1IosTablet = userAgent.includes('ipad');
    }

    inject('isC1Android', isC1Android);
    inject('isC1Ios', isC1Ios);
    inject('isApp', isApp);
    inject('isC1IosTablet', isC1IosTablet);
    inject('userAgent', userAgent || '');
};
