
    import { mapState } from 'vuex';
    import SeekerAvatar from '@/components/Seeker/SeekerAvatar';
    import C1CircularProgressBar from '@/C1Components/C1CircularProgressBar.vue';
    import AnimateRing from '@/components/Utils/Animates/AnimateRing';

    export default {
        name: 'SeekerAvatarProgressbar',

        components: {
            SeekerAvatar,
            C1CircularProgressBar,
            AnimateRing,
        },

        props: {
            size: {
                type: String,
                default: 'sm',
            },

            animate: {
                type: Boolean,
                default: false,
            },
        },

        computed: {
            ...mapState({
                $_profileScore: state => state.me.user.profile_completion,
            }),

            getProfileScore() {
                return this.$_profileScore;
            },

            getSize() {
                const size = {
                    sm: {
                        avatarSize: 32,
                        progressbarSize: 45,
                        fullStrengthSize: 45,
                    },
                    md: {
                        avatarSize: 48,
                        progressbarSize: 64,
                        fullStrengthSize: 68,
                    },
                };

                return size[this.size];
            },
        },
    };
