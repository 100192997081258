export default {
    STORE_SAVED_JOB(state, data) {
        state.savedJobs = data;
    },

    PUT_SAVED_JOB(state, data) {
        const index = state.savedJobs.findIndex(o => o.job_uuid === data.job_uuid);

        if (index >= 0) {
            state.savedJobs.splice(index, 1, data);
        } else {
            state.savedJobs.unshift(data);
        }
    },

    DESTROY_SAVED_JOB(state, id) {
        const index = state.savedJobs.findIndex(o => o.job_uuid === id);

        if (index >= 0) {
            state.savedJobs.splice(index, 1);
        }
    },

    SAVED_JOB_AFTER_LOGIN(state, data) {
        state.saveJobAfterLogin = data;
    },
};
