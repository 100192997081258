export const state = () => ({
    UserTokenNotFoundException: 'It seems this token is invalid.',
    ValidationCaptchaException: 'It seems Google Captcha is invalid.',
    ValidationException: 'It seems some fields are invalid.',
    InvalidAuthenticationException: 'Check your email or password and try again.',
    ResourceNotFoundException: 'Request not found.',
    UserNotFoundException: 'User not found.',
    SeekerNotFoundException: 'We don\'t seem to recognise that email address. Please check and try again.',
    SeekerEmailResetNotFoundException: 'It seems your reset email token is invalid.',
    EmailTokenNotValidException: 'It seems this token is invalid.',
    QueryException: 'Something went wrong.',
    ServerErrorException: 'Something went wrong.',
    UserConfirmationTokenNotFoundException: 'It seems this token is invalid.',
    UserExistsException: 'This email has already been taken.',
    JobNotFoundException: 'It seems this job is not available anymore.',
    UserResumeNotFoundException: 'It seems this resume does not exist anymore.',
    UserJobAlertNotFoundException: 'It seems this job alert does not exist anymore.',
    UserAppliedForJobException: 'It seems you already have applied for this job.',
    PromotionNotFoundException: 'It seems this promotion is not exist.',
    SmartfeedJobTokenNotFoundException: 'The apply token is not valid.',
    InvalidPasswordException: 'Your password is invalid.',
    OwnEmailNotAllowedException: 'You can not change to your current email.',
    FileImageSizeException: 'The image is too small. Min image size is 150x150px.',
    FileSizeException: 'The file size is too big. Max file size is 20MB',
    GoogleCaptchaException: 'Google captcha could not be validated.',
    SeekerPasswordResetNotFoundException: 'Re-enter the reset code and try again.',
    InvalidFilterCriteria: 'That\'s too broad. Please specify some criteria.',
    PasswordResetTooManyAttempts: 'Too many password reset attempts. Please try again in 24 hours.',
    PasswordTokenNotValid: 'Please use the latest code you received, or choose “Re-send code” to get a new one',
});
