export default {
    getAnnualSalary(context) {
        return context.dispatch('getBands', { code: 'annual_salary' });
    },

    getHourlyRate(context) {
        return context.dispatch('getBands', { code: 'hourly_rate' });
    },

    getBands(context, data) {
        let url = `v1/pay-types/bands?code=${data.code}`;
        if (data.mode) {
            url += `&mode=${data.mode}`;
        }
        return this.$axios.$get(url, { useCache: true });
    },

    async getSalarySearchFilter(context, payTypeCode = 'annual_salary') {
        let data = null;
        const url = `v1/pay-types/bands?code=${payTypeCode}&mode=search_filter`;
        if (process.server) {
            const cacheManager = require('cache-manager');
            const fsStore = require('cache-manager-fs-hash');
            const diskCache = cacheManager.caching({
                store: fsStore,
                options: {
                    path: 'diskcache', // path for cached files
                    ttl: 60 * 60, // time to life in seconds
                    subdirs: true, // create subdirectories to reduce the
                    // files in a single dir (default: false)
                },
            });
            data = await diskCache.get(url);
            if (data) { // get data from cache if exists
                return data;
            } else { // else get data from API
                return this.$axios.$get(url)
                    .then(async(response) => {
                        const data = response.data;
                        await diskCache.set(url, data);
                        return data;
                    });
            }
        } else { // else get data from API
            return this.$axios.$get(url)
                .then((response) => {
                    return response.data;
                });
        }
    },
};
