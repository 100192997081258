export default {
    checkEmail(context, { email }) {
        const url = `v1/users/check-email?email=${encodeURIComponent(email)}`;
        return this.$axios.$get(url);
    },

    createJobMatchFeedback(context, data) {
        const url = 'v1/users/job-match-feedback';
        return this.$axios.$post(url, data);
    },

    getSubscriptions(context, seekerToken) {
        const url = `v1/users/subscriptions?seeker_token=${seekerToken}`;
        return this.$axios.$get(url);
    },

    storeSubscription(context, data) {
        const url = 'v1/users/subscriptions';
        return this.$axios.$put(url, data);
    },

    deactivateSubscription(context, data) {
        const url = 'v1/users/subscriptions';
        return this.$axios.$delete(url, { data });
    },

    updateSavedSearchFrequency(context, data) {
        const url = 'v1/users/saved-searches-freq';
        return this.$axios.$put(url, data);
    },

    storeSavedSearch(context, data) {
        const url = 'v1/users/save-search';
        return this.$axios.$put(url, data);
    },

    emailSavedJob(context, data) {
        const url = 'v1/users/transport-saved-job';
        return this.$axios.$post(url, data);
    },
};
