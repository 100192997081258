
    import { mapActions, mapMutations } from 'vuex';
    import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
    import {
        faCheck as farCheck,
    } from '@fortawesome/pro-regular-svg-icons/faCheck';

    import {
        faChevronRight as fasChevronRight,
    } from '@fortawesome/pro-solid-svg-icons/faChevronRight';

    import C1Spinner from '@/C1Components/C1Spinner';

    export default {
        name: 'ResumeQualityFeedbackCta',

        components: {
            C1Spinner,
            FontAwesomeIcon,
        },

        props: {
            resume: {
                type: Object,
                default: () => {},
            },
        },

        data() {
            return {
                loader: {
                    store_resume: false,
                },
                response: {
                    status: false,
                    message: '',
                },
                icons: {
                    farCheck,
                    fasChevronRight,
                },
            };
        },

        methods: {
            ...mapActions('me', {
                $_storeTopResume: 'storeTopResume',
            }),

            ...mapMutations('me', {
                $_SET_RECENT_RESUME_REVIEW: 'SET_RECENT_RESUME_REVIEW',
            }),

            storeTopResume() {
                this.loader.store_resume = true;
                const data = {
                    resume_id: this.resume.id,
                };
                this.$_storeTopResume(data).then((response) => {
                    const responseData = response.data;
                    this.response.status = responseData.status;
                    this.response.message = 'We have successfully received your resume for review! We will get back to you shortly.';
                    this.$_SET_RECENT_RESUME_REVIEW(data);
                }).catch(() => {
                    this.response.status = 'failed';
                    this.response.status = 'There was a problem uploading your resume. Please try again.';
                }).finally(() => {
                    this.loader.store_resume = false;
                });
            },
        },
    };
