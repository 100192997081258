
    import { mapState, mapGetters, mapMutations } from 'vuex';
    import SidebarBurger from '@/components/Sidebar/SidebarBurger';
    import SidebarStrengthMini from '@/components/Sidebar/SidebarStrengthMini';
    import SidebarMenuMini from '@/components/Sidebar/SidebarMenuMini';

    export default {
        name: 'SidebarMini',

        components: {
            SidebarBurger,
            SidebarStrengthMini,
            SidebarMenuMini,
        },

        computed: {
            ...mapState({
                $_appInfo: state => state.appInfo,
            }),

            ...mapGetters('appInfo', {
                $_isAppWarning: 'isAppWarning',
            }),
        },

        methods: {
            ...mapMutations('appInfo', {
                $_openSidebar: 'OPEN_SIDEBAR',
                $_closeSidebar: 'CLOSE_SIDEBAR',
            }),
        },
    };
