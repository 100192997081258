
    import HtmlHeader from '@/components/Partials/HtmlHeader';

    export default {
        name: 'Blank',

        mixins: [HtmlHeader],

        head() {
            return this.head;
        },
    };
