
    import { mapActions } from 'vuex';
    import error500 from '@/components/Error/error500.vue';

    export default {
        components: {
            error500,
        },

        layout: 'default',

        props: {
            error: {
                type: Object,
                default: null,
            },
        },

        computed: {
            errorPage() {
                if (this.error.statusCode === 410) {
                    return 'expired_job';
                }
                // catch everything else
                return 'error500';
            },
        },

        methods: {
            ...mapActions('log', {
                $_storeLog: 'storeLog',
            }),
        },
    };
