export const state = () => ({
    applyTypes: {
        APPLY_TYPE_ONSITE: 'onsite',
        APPLY_TYPE_ONSITE_EMAIL: 'email',
        APPLY_TYPE_OFFSITE_APPLY: 'offsite-apply',
        APPLY_TYPE_OFFSITE_JOB_VIEW: 'offsite-job-view',
        APPLY_TYPE_DISABLE_APPLY: 'disable-apply',
    },
    applyStatus: {
        APPLY_STATUS_NEW: 'new',
        APPLY_STATUS_INTERVIEWD: 'interviewed',
        APPLY_STATUS_SHORTLISTED: 'shortlisted',
        APPLY_STATUS_UNSUCCESSFUL: 'unsuccessful',
        APPLY_STATUS_OFFERED: 'offered',
    },
    jobType: {
        JOB_TYPE_FULL_TIME: 'Full Time',
        JOB_TYPE_PART_TIME: 'Part Time',
        JOB_TYPE_CASUAL: 'Casual/Holiday',
    },
    payTypeCode: {
        PAY_TYPE_CODE_ANNUAL_SALARY: 'annual_salary',
        PAY_TYPE_CODE_HOURLY_RATE: 'hourly_rate',
        PAY_TYPE_CODE_BI_WEEKLY: 'bi_weekly',
        PAY_TYPE_CODE_PER_DAY: 'per_day',
        PAY_TYPE_CODE_PER_MONTH: 'per_month',
        PAY_TYPE_CODE_PER_WEEK: 'per_week',
    },
    payTypeFormat: {
        hourly_rate: {
            format: '0,0.00',
            maximumValue: 150,
            maximumLabel: '150.00+',
            suffix: 'Hourly rate',
        },
        per_day: {
            format: '0,0',
            maximumValue: 1000,
            maximumLabel: '1,000+',
            suffix: 'Daily rate',
        },
        per_week: {
            format: '0,0',
            maximumValue: 5000,
            maximumLabel: '5,000+',
            suffix: 'Weekly rate',
        },
        bi_weekly: {
            format: '0,0',
            maximumValue: 10000,
            maximumLabel: '10,000+',
            suffix: 'Fortnightly rate',
        },
        per_month: {
            format: '0,0',
            maximumValue: 20000,
            maximumLabel: '20,000+',
            suffix: 'Monthly rate',
        },
        annual_salary: {
            format: '0,0',
            maximumValue: 350000,
            maximumLabel: '350,000+',
            suffix: 'Annual salary',
        },
    },
    applyInterceptions: {
        FLOW: 'FLOW',
    },
    widgets: {
        JOB_ALERT: 'job_alert',
        GLOBAL_ALERT: 'global_alert',
        RESUME_REVIEW: 'resume_review',
        RESUME_UPLOAD: 'resume_upload',
        SALARY_UPDATE: 'salary_update',
        VISIBLE_PROFILE: 'visible_profile',
        APOLLO_ONBOARDING: 'apollo_onboarding',
    },
    states: [
        { name: 'Northern Territory', code: 'nt', slug: 'northern-territory' },
        { name: 'Queensland', code: 'qld', slug: 'queensland' },
        { name: 'Western Australia', code: 'wa', slug: 'western-australia' },
        { name: 'New South Wales', code: 'nsw', slug: 'new-south-wales' },
        { name: 'Victoria', code: 'vic', slug: 'victoria' },
        { name: 'Australian Capital Territory', code: 'act', slug: 'australian-capital-territory' },
        { name: 'South Australia', code: 'sa', slug: 'south-australia' },
        { name: 'Tasmania', code: 'tas', slug: 'tasmania' },
    ],
    resumeQualityFeedback: {
        description: '<p>Your resume quality score is determined by how well your resume is scanned and understood by recruitment technology.</p>\n' +
                     '<p>Most recruitment firms and corporate businesses use resume scanning systems as their first layer of candidate review. We provide you with feedback to ensure your resume progresses through this initial application process seamlessly.</p>',
    },
});
