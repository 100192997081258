import Cookie from 'js-cookie';
import has from 'lodash/has';
import { removeNulls, getKeyValue } from '@/utils/helpers';

export default {
    async pushEvent({ commit, state, dispatch }, obj) {
        if (process.server) {
            return;
        }
        try {
            const bots = /bot|googlebot|crawler|spider|robot|crawling/i;
            if (bots.test(navigator.userAgent)) {
                return;
            }

            await Promise.all([
                dispatch('saveViewPortSize'),
                dispatch('saveDeviceResolution'),
                dispatch('saveDomainSession'),
                dispatch('saveCurrentPage'),
                dispatch('saveSeekerData'),
                dispatch('saveQueryString'),
            ]);

            commit('SET', { key: 'site', value: this.$siteCode });
            commit('SET', { key: 'platform_code', value: this.$platformCode });

            if (obj.page_referral_element) {
                commit('SET', { key: 'page_referral_element', value: obj.page_referral_element });
            }
            // Clear all empty and null index
            const data = removeNulls(Object.assign(JSON.parse(JSON.stringify(state)), obj));
            if (data.event === 'job_view' || data.event === 'job_apply_start') {
                const url = 'v1/push-event';
                return this.$axios.$post(url, data);
            } else {
                const url = `${process.env.API_GATEWAY_URL}/logs/events`;
                const instance = this.$axios.create();

                delete instance.defaults.headers.common.Authorization;
                delete instance.defaults.headers.common['job-buckets'];
                delete instance.defaults.headers.common['platform-code'];
                delete instance.defaults.headers.common['seeker-buckets'];
                delete instance.defaults.headers.common['site-code'];

                return instance.$post(url, { Data: data }, { headers: { c1key: 'HoCBXldx1l9ryZzfob3uo78MQQrTtOnQ2dyqDaYg' } });
            }
        } catch (e) {
            return true;
        }
    },

    pushSeekerJobEvent({ state }, data) {
        if (state.seeker_email) {
            const payload = {
                email: state.seeker_email,
                site_event: {
                    event: data.event,
                    job: {
                        id: data.job_id,
                        uuid: data.job_uuid,
                        job_title: data.job_title,
                        normalised_job_title: {
                            id: data.job_njt_id,
                            value: data.job_njt,
                        },
                        category: {
                            id: data.job_category_id,
                            value: data.job_category,
                        },
                        occupation: {
                            id: data.job_occupation_id,
                            value: data.job_occupation,
                        },
                        location: {
                            id: data.job_location_id,
                            type: data.job_location_type,
                            label: data.job_location,
                            display_label: data.job_location,
                            region_name: data.j_loc_region_name,
                            area_name: data.j_loc_area_name,
                            state_name: data.j_loc_state_name,
                            suburb_name: data.j_loc_suburb_name,
                            suburb_location_id: data.j_loc_suburb_id,
                            area_location_id: data.j_loc_area_id,
                            region_location_id: data.j_loc_region_id,
                            state_location_id: data.j_loc_state_id,
                            state_code: data.j_loc_state_code,
                            country_location_id: data.j_loc_country_id,
                            country_name: data.j_loc_country_name,
                            country_code: data.j_loc_country_code,
                            postcode: data.j_loc_postcode,
                        },
                        pay: {
                            type: data.job_pay_type,
                            min: data.job_pay_min,
                            max: data.job_pay_max,
                            estimated_min: data.job_pay_estimated_min,
                            estimated_max: data.job_pay_estimated_max,
                            is_hidden: Boolean(data.job_pay_hidden),
                        },
                        brand: {
                            id: data.job_brand_id,
                            value: data.job_brand_name,
                        },
                        work_type: {
                            id: data.job_type_id,
                            value: data.job_type,
                        },
                        contract_type: {
                            id: data.contract_type_id,
                            value: data.contract_type,
                        },
                        job_mode: {
                            id: data.job_mode_id,
                            value: data.job_mode,
                        },
                        company_size: {
                            id: data.company_size_id,
                            value: data.company_size,
                        },
                        industry: data.job_industry,
                        sector: data.job_sector,
                    },
                },
            };

            if (data.event === 'job_apply_start') {
                if (data.seeker_match_score && data.seeker_match_score.percentage) {
                    payload.site_event.job.match_score = data.seeker_match_score.percentage;
                }
            }

            const url = `${process.env.APP_URL}/email/audience/push-event`;
            // console.log(url);
            // console.log(payload);
            return this.$axios.$post(url, payload);
        }
    },

    pushSeekerJobSearchEvent({ state }, data) {
        const searchData = { ...data.search_filters };

        if (state.seeker_email) {
            if (searchData.categories && searchData.categories.length) {
                searchData.occupations = [];
                searchData.categories.forEach((item) => {
                    if (item.occupations.length) {
                        searchData.occupations.push(...item.occupations);
                    }
                });
            }

            const payload = {
                email: state.seeker_email,
                site_event: {
                    event: data.event,
                    search: {
                        search_keyword: searchData.search_keywords || searchData.search || null,
                        category: getKeyValue(searchData.categories),
                        occupation: getKeyValue(searchData.occupations),
                        location: {
                            id: searchData.location?.id || null,
                            type: searchData.location?.type || null,
                            label: searchData.location?.label || null,
                            display_label: searchData.location?.display_label || null,
                            region_name: searchData.location?.region_name || null,
                            area_name: searchData.location?.area_name || null,
                            state_name: searchData.location?.state_name || null,
                            suburb_name: searchData.location?.suburb_name || null,
                            suburb_location_id: searchData.location?.suburb_location_id || null,
                            area_location_id: searchData.location?.area_location_id || null,
                            region_location_id: searchData.location?.region_location_id || null,
                            state_location_id: searchData.location?.state_location_id || null,
                            state_code: searchData.location?.state_code || null,
                            country_location_id: searchData.location?.country_location_id || null,
                            country_name: searchData.location?.country_name || null,
                            country_code: searchData.location?.country_code || null,
                            postcode: searchData.location?.postcode || null,
                        },
                        pay: {
                            min: searchData.pay_min ? parseInt(searchData.pay_min.value) : 0,
                            max: searchData.pay_max ? parseInt(searchData.pay_max.value) : null,
                        },
                        work_type: getKeyValue(searchData.job_type),
                        contract_type: getKeyValue(searchData.contract_type),
                        job_mode: getKeyValue(searchData.job_mode),
                        industry: getKeyValue(searchData.industry),
                        brand: getKeyValue(searchData.brands),
                    },
                },
            };
            const url = `${process.env.APP_URL}/email/audience/push-event`;
            return this.$axios.$post(url, payload);
        }
    },

    saveViewPortSize({ commit }) {
        if (typeof window === 'undefined') {
            return false;
        }

        const viewport = {
            width: window.innerWidth,
            height: window.innerHeight,
        };

        commit('SET', { key: 'viewport_size', value: viewport });
    },

    saveDeviceResolution({ commit }) {
        if (typeof window === 'undefined') {
            return false;
        }

        const resolution = {
            width: window.screen.availWidth,
            height: window.screen.availHeight,
        };

        commit('SET', { key: 'device_resolution', value: resolution });
    },

    saveDomainSession({ commit }) {
        commit('SET', { key: 'guest_email', value: Cookie.get('guest_email') });
        commit('SET', { key: 'domain_session_id', value: Cookie.get(process.env.COOKIE_DOMAIN_SESSION) });
    },

    saveCurrentPage({ commit }) {
        if (typeof window === 'undefined') {
            return false;
        }

        commit('SET', { key: 'current_page_url', value: window.location.pathname });
    },

    saveSeekerData({ commit, rootState }) {
        if (has(rootState, 'me.user.id')) {
            commit('SET', { key: 'seeker_id', value: rootState.me.user.id });
        }

        if (has(rootState, 'me.user.email_hash')) {
            commit('SET', { key: 'seeker_hash', value: rootState.me.user.email_hash });
        }

        if (has(rootState, 'me.user.email')) {
            commit('SET', { key: 'seeker_email', value: rootState.me.user.email });
        }
    },

    saveQueryString({ commit }) {
        if (typeof window === 'undefined') {
            return false;
        }

        const search = window.location.search;
        const objURL = {};
        search.replace(
            /([^?=&]+)(=([^&]*))?/g,
            function($0, $1, $2, $3) {
                objURL[$1] = $3;
            },
        );

        if (objURL.page_referral_element) {
            commit('SET', { key: 'page_referral_element', value: decodeURIComponent(objURL.page_referral_element) });
        }

        if (objURL.utm_source) {
            commit('SET', {
                key: 'utm',
                value: {
                    source: objURL.utm_source,
                    medium: objURL.utm_medium,
                    campaign: objURL.utm_campaign,
                    content: objURL.utm_content,
                    term: objURL.utm_term,
                },
            });
        }

        if (objURL.step) {
            commit('SET', { key: 'step', value: objURL.step });
        }
    },
};
