export default {
    getNotifications(context, params) {
        let url = 'v1/me/notifications';
        if (params) {
            const queryString = Object.keys(params).map(key => `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`).join('&');
            if (queryString) {
                url += `?${queryString}`;
            }
        }
        return this.$axios.$get(url);
    },

    checkNotifications() {
        const url = 'v1/me/check/notifications';
        return this.$axios.$get(url);
    },

    destroyNotification(context, id) {
        const url = `v1/me/notification/${id}`;
        return this.$axios.$delete(url);
    },

    updateNotification(context, { id, data }) {
        const url = `v1/me/notification/${id}`;
        return this.$axios.$put(url, data);
    },

    markNotificationsOld() {
        const url = 'v1/me/notifications/mark-old';
        return this.$axios.$put(url);
    },
};
