export default ({ app }, inject) => {
    // eslint-disable-next-line no-unused-vars
    const manifest = {
        short_name: app.$platformCode,
        name: app.$siteName,
        description: 'Job Search - Find Your Next Career Opportunity',
        icons: [
            {
                src: app.$logo,
                type: 'image/png',
                sizes: '192x192',
            },
            {
                src: app.$logo,
                type: 'image/png',
                sizes: '512x512',
            },
        ],
        start_url: `${app.$siteUrl}?source=pwa`,
        background_color: '#fff',
        display: 'standalone',
        scope: app.$siteUrl,
        theme_color: '#4c8132',
    };

    const manifestString = JSON.stringify(manifest);
    const base64 = Buffer.from(manifestString).toString('base64');
    const manifestUrl = `data:text/javascript;base64,${base64}`;
    inject('manifestUrl', manifestUrl);
};
