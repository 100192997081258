
    import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
    import {
        faSkull as fasSkull,
    } from '@fortawesome/pro-solid-svg-icons/faSkull';

    export default {
        name: 'ErrorMessage',

        components: {
            FontAwesomeIcon,
        },

        data() {
            return {
                icons: {
                    fasSkull,
                },
            };
        },
    };
