export default {
    syncAttributes(context, { id, data }) {
        const url = `v1/me/attributes/${id}/sync`;
        return this.$axios.$put(url, data);
    },

    getAttributes(context, attributeId) {
        let url = 'v1/me/attributes';
        if (attributeId) {
            url += `/${attributeId}/options`;
        }

        return this.$axios.$get(url);
    },

    attachAttribute(context, { id, data }) {
        const url = `v1/me/attributes/${id}/attach`;
        return this.$axios.$put(url, data);
    },

    storeAttribute(context, { id, data }) {
        const url = `v1/me/attributes/${id}/options`;
        return this.$axios.$post(url, data);
    },

    detachAttribute(context, { id, data }) {
        const url = `v1/me/attributes/${id}/detach`;
        return this.$axios.$put(url, data);
    },
};
