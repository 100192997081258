export default {
    STORE_WORK_LOCATION(state, data) {
        state.workLocations = data;
    },

    PUT_WORK_LOCATION(state, data) {
        const index = state.workLocations.findIndex(o => o.id === data.id);

        if (index >= 0) {
            state.workLocations.splice(index, 1, data);
        } else {
            state.workLocations.unshift(data);
        }
    },

    DESTROY_WORK_LOCATION(state, id) {
        const index = state.workLocations.findIndex(o => o.id === id);

        if (index >= 0) {
            state.workLocations.splice(index, 1);
        }
    },
};
