
    import { mapState, mapActions, mapMutations } from 'vuex';
    import has from 'lodash/has';
    import Cookie from 'js-cookie';

    import datalayer from '@/utils/datalayer';
    import { camelize } from '@/utils/helpers';
    import { jobData } from '@/utils/jobData';

    import BackButton from '@/components/Utils/BackButton';

    export default {
        name: 'PromptModal',

        components: {
            BackButton,
        },

        props: {
            initPrompt: {
                type: Object,
                default: () => { return {}; },
            },

            noEvents: {
                type: Boolean,
                default: false,
            },
        },

        data() {
            return {
                isOpen: true,
                isPromptSuccess: false,
                isPromptLoaded: false,
                prompt: {
                    component_name: '',
                    code: '',
                    layout: '',
                    page_referral_element: '',
                    site_position: '',
                },
                prevPrompt: {},
                mouseMoveCounter: 0,
            };
        },

        computed: {
            ...mapState({
                $_showPrompt: state => state.prompt.showPrompt,
                $_job: state => state.job.job,
                $_scoringData: state => state.me.scoringData,
                $_search: state => state.search,
                $_isDomainSessionAvailable: state => state.eventTracking.isDomainSessionAvailable,
            }),

            showModal: {
                get() {
                    return this.isOpen && this.getPromptComponent !== '';
                },

                set(value) {
                    this.isOpen = value;
                },
            },

            getPromptComponent() {
                return this.prompt.component_name || '';
            },

            getPromptComponentRef() {
                return camelize(this.prompt.component_name) || '';
            },

            getPromptTitle() {
                return this.prompt.title || 'Prompt title';
            },

            getPromptSize() {
                return this.prompt.layout || 'sm';
            },
        },

        watch: {
            initPrompt(value) {
                this.promptSwitch(value);
            },

            $_isDomainSessionAvailable(value) {
                if (value) {
                    if (typeof document !== 'undefined') {
                        document.addEventListener('mousemove', this.getPrompts);
                    }
                }
            },
        },

        created() {
            if (this.initPrompt && this.initPrompt.component_name) {
                this.prompt = this.initPrompt;
                this.openModal();
                this.onPromptImpression();
                this.$_SET_AUTH_OPTION({
                    key: 'sitePosition',
                    value: this.getSitePosition(),
                });
            }
        },

        mounted() {
            const url = new URL(window.location.href);
            const utmSource = url.searchParams.get('utm_source');
            if (utmSource === 'JobAlerts') {
                this.$_DISABLE_PROMPT();
            }
        },

        methods: {
            ...mapActions('prompt', {
                $_getPrompt: 'getPrompt',
            }),

            ...mapActions('eventTracking', {
                $_pushEvent: 'pushEvent',
                $_saveDomainSession: 'saveDomainSession',
            }),

            ...mapMutations('prompt', {
                $_DISABLE_PROMPT: 'DISABLE_PROMPT',
            }),

            ...mapMutations('auth', {
                $_SET_AUTH_OPTION: 'SET_AUTH_OPTION',
            }),

            onClose() {
                this.onPromptAbandon();
                this.$_DISABLE_PROMPT();
                this.closeModal();
                if (this.isPromptSuccess && this.$route.name === 'discover') {
                    const currentPath = window.location.href;
                    const finalPath = new URL(currentPath);
                    finalPath.searchParams.delete('onboarding');
                    window.location.href = finalPath;
                }
            },

            onDone() {
                if (this.$route.name === 'discover') {
                    const currentPath = window.location.href;
                    const finalPath = new URL(currentPath);
                    finalPath.searchParams.delete('onboarding');
                    window.location.href = finalPath;
                } else {
                    this.$_DISABLE_PROMPT();
                    this.closeModal();
                }
            },

            openModal() {
                this.showModal = true;
            },

            closeModal() {
                this.isOpen = false;
                this.$emit('close', this.prompt);
            },

            getPrompts() {
                document.removeEventListener('mousemove', this.getPrompts);

                if (this.ifNoInitPrompt()) {
                    const domainSession = Cookie.get(process.env.COOKIE_DOMAIN_SESSION);
                    if (!domainSession) {
                        this.$_saveDomainSession();
                    }
                    if (this.$_showPrompt) {
                        const payload = {
                            domain_session_id: Cookie.get(process.env.COOKIE_DOMAIN_SESSION),
                            route_name: this.$route.name,
                        };
                        this.$_getPrompt(payload).then((response) => {
                            this.prompt.component_name = response.data.component_name;
                            this.prompt.code = response.data.code;
                            this.prompt.title = response.data.title;
                            this.prompt.layout = response.data.layout;
                            this.prompt.description = response.data.description;
                            this.prompt.site_position = this.getSitePosition();

                            if (this.prompt.component_name) {
                                this.onPromptImpression();
                                this.$_SET_AUTH_OPTION({
                                    key: 'sitePosition',
                                    value: this.getSitePosition(),
                                });
                            }
                        });
                    }
                }
            },

            ifNoInitPrompt() {
                return typeof this.initPrompt.component_name === 'undefined';
            },

            promptSwitch(prompt) {
                this.prevPrompt = this.prompt;
                this.prompt = prompt;
                this.isOpen = true;
                this.onPromptImpression();
            },

            handleBackButton() {
                this.promptSwitch(this.prevPrompt);
            },

            onPromptAbandon() {
                if (!this.isPromptSuccess) {
                    this.pushEvent('prompt_abandoned');
                }
            },

            onPromptSuccess(data) {
                this.isPromptSuccess = true;
                this.pushEvent('prompt_successful');
                this.$emit('promptSuccess', data);
            },

            onPromptImpression() {
                this.pushEvent('prompt_impression');
            },

            promptStart(eventName) {
                this.pushEvent(eventName);
            },

            promptComplete(eventName) {
                this.pushEvent(eventName);
            },

            pushEvent(eventName) {
                if (!this.noEvents) {
                    let data = {};
                    if (this.$_job.id) {
                        data = {
                            event: eventName,
                            code: this.prompt.code,
                            title: this.prompt.title,
                            page_referral_element: 'prompt_logic',
                            site_position: this.getSitePosition(),
                            seeker_match_score: this.$_job.seeker_match_score,
                            seeker_data: this.$_scoringData,
                            ...jobData(this.$_job),
                        };
                    } else {
                        data = {
                            event: eventName,
                            code: this.prompt.code,
                            page_referral_element: 'prompt_logic',
                            site_position: this.getSitePosition(),
                            seeker_data: this.$_scoringData,
                        };
                    }
                    if (has(this.$_search, 'searchPayload.search_results.jobs')) {
                        data.job_uuids = this.$_search.searchPayload.search_results.jobs.map(o => o.uuid);
                    }
                    if (has(this.$_search, 'searchPayload.search_filters')) {
                        data.search_filters = this.$_search.searchPayload.search_filters;
                    }

                    if (this.prompt.page_referral_element) {
                        data.page_referral_element = this.prompt.page_referral_element;
                    }
                    this.$_pushEvent(data);
                    datalayer(data);
                }
            },

            getSitePosition() {
                if (this.prompt.site_position) {
                    return this.prompt.site_position;
                }

                if (this.$route.name === 'default-search-results' && !this.$route.query.jobview) {
                    return 'search_results_upload_resume_prompt';
                } else if (this.$route.name === 'default-search-results' && this.$route.query.jobview) {
                    return 'job_view_upload_resume_prompt';
                } else if (this.$route.name === 'jobview-slug-id') {
                    return 'job_view_upload_resume_prompt';
                } else if (this.$route.name === 'discover' && !this.$route.query.jobview) {
                    return 'discover_upload_resume_prompt';
                } else if (this.$route.name === 'discover' && this.$route.query.jobview) {
                    return 'job_view_upload_resume_prompt';
                } else if (this.$route.name === 'profile') {
                    return 'profile_resumes_upload_resume_prompt';
                } else {
                    return 'prompt_logic';
                }
            },
        },
    };
