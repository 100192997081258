
    import ErrorMessage from '@/components/Error/ErrorMessage';

    export default {
        name: 'Error500',

        components: {
            ErrorMessage,
        },

        props: {
            error: {
                type: Object,
                default: null,
            },
        },
    };
