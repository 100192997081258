export const state = () => ({
    payTypes: {
        1: {
            id: 1,
            code: 'annual_salary',
            label: 'Annually',
        },
        2: {
            id: 2,
            code: 'hourly_rate',
            label: 'Hourly',
        },
        4: {
            id: 4,
            code: 'per_day',
            label: 'Daily',
        },
    },
});
