
    import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
    import {
        faExclamationTriangle as farExclamationTriangle,
    } from '@fortawesome/pro-regular-svg-icons/faExclamationTriangle';

    import {
        faSeedling as farSeedling,
    } from '@fortawesome/pro-regular-svg-icons/faSeedling';

    import {
        faSkullCrossbones as fasSkullCrossbones,
    } from '@fortawesome/pro-solid-svg-icons/faSkullCrossbones';

    import {
        faAsterisk as fasAsterisk,
    } from '@fortawesome/pro-solid-svg-icons/faAsterisk';

    export default {
        name: 'ResumeQualityFeedbackIssues',

        components: {
            FontAwesomeIcon,
        },

        props: {
            score: {
                type: Array,
                default: () => [],
            },
        },

        data() {
            return {
                mutableScore: [],
                icons: {
                    farExclamationTriangle,
                    fasSkullCrossbones,
                    fasAsterisk,
                    farSeedling,
                },
            };
        },

        computed: {
            scoreSeverity() {
                return this.mutableScore.filter(o => o.issues.length);
            },
        },

        created() {
            this.mutableScore = JSON.parse(JSON.stringify(this.score));
        },

        methods: {
            open(score) {
                this.$set(score, 'is_open', true);
            },

            close(score) {
                this.$set(score, 'is_open', false);
            },
        },
    };
