export default {
    OPEN_ONBOARDING_QUESTIONS(state) {
        state.is_apply_form_open = true;
        state.is_basic_questions_open = false;
        state.is_onboarding_questions_open = true;
        state.is_custom_questions_open = false;
        state.is_signin_modal_open = false;
    },

    CLOSE_ONBOARDING_QUESTIONS(state) {
        state.is_onboarding_form_open = false;
    },
};
