export default ({ app }, inject) => {
    const accountUrl = `${app.$siteUrl}account`;
    const onboardingUrl = `${app.$siteUrl}onboarding`;

    const routes = {
        careeroneUrl: 'https://www.careerone.com.au',
        advertisers: app.$hiringUrl,
        terms_of_use: `${app.$siteUrl}terms`,
        privacy_policy: `${app.$siteUrl}privacy`,
        resume: 'https://resumewriting.careerone.com.au/',
        career_advice: 'https://www.careerone.com.au/career-advice/',
        contact_us: 'https://www.careerone.com.au/contact-us',
        onboarding: onboardingUrl,
        account: accountUrl,
        courses: 'https://www.careerone.com.au/courses/',
    };

    inject('staticRoutes', routes);
};
