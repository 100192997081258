export default {
    async getCategoriesWithOccupation() {
        let data = null;
        const url = 'v1/categories?include_occupation=yes';
        if (process.server) {
            const cacheManager = require('cache-manager');
            const fsStore = require('cache-manager-fs-hash');
            const diskCache = cacheManager.caching({
                store: fsStore,
                options: {
                    path: 'diskcache', // path for cached files
                    ttl: 60 * 60, // time to life in seconds
                    subdirs: true, // create subdirectories to reduce the
                    // files in a single dir (default: false)
                },
            });
            data = await diskCache.get(url);
            if (data) { // get data from cache if exists
                return data;
            } else { // else get data from API
                return this.$axios.$get(url)
                    .then(async(response) => {
                        const data = response.data;
                        await diskCache.set(url, data);
                        return data;
                    });
            }
        } else { // else get data from API
            return this.$axios.$get(url)
                .then((response) => {
                    return response.data;
                });
        }
    },

    getParseCategory(context, data) {
        const url = 'v1/parse/category';
        return this.$axios.$post(url, data);
    },
};
