
    import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
    import {
        faSignOut as farSignOut,
    } from '@fortawesome/pro-regular-svg-icons/faSignOut';

    export default {
        name: 'SidebarFooter',

        components: {
            FontAwesomeIcon,
        },

        data() {
            return {
                icons: {
                    farSignOut,
                },
            };
        },
    };
