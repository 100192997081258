import Vue from 'vue';
import PromptModal from '@/components/Prompts/PromptModal';

const components = {
    PromptModal,
};

Object.entries(components).forEach(([name, component]) => {
    Vue.component(name, component);
});
