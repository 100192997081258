
    import { mapMutations } from 'vuex';

    import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

    import { faCheckCircle as farCheckCircle } from '@fortawesome/pro-regular-svg-icons/faCheckCircle';
    import { faBookmark as farBookmark } from '@fortawesome/pro-regular-svg-icons/faBookmark';
    import { faStar as farStar } from '@fortawesome/pro-regular-svg-icons/faStar';
    import { faCompass as farCompass } from '@fortawesome/pro-regular-svg-icons/faCompass';
    import { faEyeSlash as farEyeSlash } from '@fortawesome/pro-regular-svg-icons/faEyeSlash';
    import { faSignOut as farSignOut } from '@fortawesome/pro-regular-svg-icons/faSignOut';

    import { faExternalLink as fasExternalLink } from '@fortawesome/pro-solid-svg-icons/faExternalLink';

    export default {
        name: 'SidebarMenu',

        components: {
            FontAwesomeIcon,
        },

        data() {
            return {
                icons: {
                    farCheckCircle,
                    farBookmark,
                    farStar,
                    farCompass,
                    farEyeSlash,
                    farSignOut,
                    fasExternalLink,
                },
            };
        },

        computed: {
            menu() {
                return [
                    {
                        label: 'Discover jobs',
                        icon: this.icons.farCompass,
                        number: 0,
                        link: { name: 'discover' },
                        is_active: this.$route.path === '/discover',
                    },
                    {
                        label: 'Saved searches',
                        icon: this.icons.farBookmark,
                        number: 0,
                        link: { name: 'my-searches' },
                        is_active: this.$route.path === '/my-searches',
                    },
                    {
                        label: 'Saved jobs',
                        icon: this.icons.farStar,
                        number: 0,
                        link: { name: 'my-activity-slug', params: { slug: 'saved-jobs' } },
                        is_active: this.$route.path === '/my-activity/saved-jobs',
                    },
                    {
                        label: 'Applied',
                        icon: this.icons.farCheckCircle,
                        number: 0,
                        link: { name: 'my-activity-slug', params: { slug: 'applied' } },
                        is_active: this.$route.path === '/my-activity/applied',
                    },
                    {
                        label: 'Hidden jobs',
                        icon: this.icons.farEyeSlash,
                        number: 0,
                        link: { name: 'my-activity-slug', params: { slug: 'hidden-jobs' } },
                        is_active: this.$route.path === '/my-activity/hidden-jobs',
                    },
                ];
            },

            getSettingsLink() {
                return `${this.$staticRoutes.account}/settings?redirect=${this.$route.fullPath}`;
            },
        },

        methods: {
            ...mapMutations('appInfo', {
                $_closeSidebar: 'CLOSE_SIDEBAR',
            }),

            closeSidebar() {
                this.$_closeSidebar();
            },
        },
    };
