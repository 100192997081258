export const NuxtDynamic = () => import('../../node_modules/@blokwise/dynamic/src/components/Dynamic.vue' /* webpackChunkName: "components/nuxt-dynamic" */).then(c => wrapFunctional(c.default || c))
export const CertificationPrompt = () => import('../../components/Prompts/Components/CertificationPrompt.vue' /* webpackChunkName: "components/certification-prompt" */).then(c => wrapFunctional(c.default || c))
export const CoverLetterPrompt = () => import('../../components/Prompts/Components/CoverLetterPrompt.vue' /* webpackChunkName: "components/cover-letter-prompt" */).then(c => wrapFunctional(c.default || c))
export const EducationPrompt = () => import('../../components/Prompts/Components/EducationPrompt.vue' /* webpackChunkName: "components/education-prompt" */).then(c => wrapFunctional(c.default || c))
export const GetJobMatchesPromptLg = () => import('../../components/Prompts/Components/GetJobMatchesPromptLg.vue' /* webpackChunkName: "components/get-job-matches-prompt-lg" */).then(c => wrapFunctional(c.default || c))
export const GetJobMatchesPromptMd = () => import('../../components/Prompts/Components/GetJobMatchesPromptMd.vue' /* webpackChunkName: "components/get-job-matches-prompt-md" */).then(c => wrapFunctional(c.default || c))
export const GetJobMatchesPromptShort = () => import('../../components/Prompts/Components/GetJobMatchesPromptShort.vue' /* webpackChunkName: "components/get-job-matches-prompt-short" */).then(c => wrapFunctional(c.default || c))
export const GetJobMatchesPromptSm = () => import('../../components/Prompts/Components/GetJobMatchesPromptSm.vue' /* webpackChunkName: "components/get-job-matches-prompt-sm" */).then(c => wrapFunctional(c.default || c))
export const LoginPrompt = () => import('../../components/Prompts/Components/LoginPrompt.vue' /* webpackChunkName: "components/login-prompt" */).then(c => wrapFunctional(c.default || c))
export const ResumePrompt = () => import('../../components/Prompts/Components/ResumePrompt.vue' /* webpackChunkName: "components/resume-prompt" */).then(c => wrapFunctional(c.default || c))
export const ResumeUploadOptionPrompt = () => import('../../components/Prompts/Components/ResumeUploadOptionPrompt.vue' /* webpackChunkName: "components/resume-upload-option-prompt" */).then(c => wrapFunctional(c.default || c))
export const TopResumePrompt = () => import('../../components/Prompts/Components/TopResumePrompt.vue' /* webpackChunkName: "components/top-resume-prompt" */).then(c => wrapFunctional(c.default || c))
export const WorkExperiencePrompt = () => import('../../components/Prompts/Components/WorkExperiencePrompt.vue' /* webpackChunkName: "components/work-experience-prompt" */).then(c => wrapFunctional(c.default || c))
export const WorkSkillsPrompt = () => import('../../components/Prompts/Components/WorkSkillsPrompt.vue' /* webpackChunkName: "components/work-skills-prompt" */).then(c => wrapFunctional(c.default || c))
export const OnBoardingComplete = () => import('../../components/Prompts/Components/OnBoardingPrompt/OnBoardingComplete.vue' /* webpackChunkName: "components/on-boarding-complete" */).then(c => wrapFunctional(c.default || c))
export const OnBoardingGetStarted = () => import('../../components/Prompts/Components/OnBoardingPrompt/OnBoardingGetStarted.vue' /* webpackChunkName: "components/on-boarding-get-started" */).then(c => wrapFunctional(c.default || c))
export const OnBoardingOccupationPrompt = () => import('../../components/Prompts/Components/OnBoardingPrompt/OnBoardingOccupationPrompt.vue' /* webpackChunkName: "components/on-boarding-occupation-prompt" */).then(c => wrapFunctional(c.default || c))
export const OnBoardingPrompt = () => import('../../components/Prompts/Components/OnBoardingPrompt/OnBoardingPrompt.vue' /* webpackChunkName: "components/on-boarding-prompt" */).then(c => wrapFunctional(c.default || c))
export const OnBoardingSeekerDetailsPrompt = () => import('../../components/Prompts/Components/OnBoardingPrompt/OnBoardingSeekerDetailsPrompt.vue' /* webpackChunkName: "components/on-boarding-seeker-details-prompt" */).then(c => wrapFunctional(c.default || c))
export const OnBoardingWorkExperiencePrompt = () => import('../../components/Prompts/Components/OnBoardingPrompt/OnBoardingWorkExperiencePrompt.vue' /* webpackChunkName: "components/on-boarding-work-experience-prompt" */).then(c => wrapFunctional(c.default || c))
export const OnBoardingWorkLocationPrompt = () => import('../../components/Prompts/Components/OnBoardingPrompt/OnBoardingWorkLocationPrompt.vue' /* webpackChunkName: "components/on-boarding-work-location-prompt" */).then(c => wrapFunctional(c.default || c))
export const OnBoardingWorkSkillsPrompt = () => import('../../components/Prompts/Components/OnBoardingPrompt/OnBoardingWorkSkillsPrompt.vue' /* webpackChunkName: "components/on-boarding-work-skills-prompt" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
