export const state = () => ({
    locales: [
        {
            code: 'AU',
            country_id: 15299,
            domain: new URL(process.env.APP_URL).host,
            app_url: process.env.APP_URL,
        },
        {
            code: 'NZ',
            country_id: 15335,
            domain: new URL(process.env.APP_NZ_URL).host,
            app_url: process.env.APP_NZ_URL,
        },
    ],
    locale: 'AU',
});

export const mutations = {
    SET_LANG(state, locale) {
        if (state.locales.find(o => o.code === locale)) {
            state.locale = locale;
        }
    },

    SET_LOCALES_URL(state) {
        state.locales.map((o) => {
            o.domain = new URL(this.$siteUrl).host;
            o.app_url = this.$siteUrl;
        });
    },
};

export const getters = {
    currentLocale(state) {
        return state.locales.find(o => o.code === state.locale);
    },

    currentAppUrl(state, getters) {
        return getters.currentLocale.app_url;
    },
};
