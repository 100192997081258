import Vue from 'vue';

import {
  BContainer,
  BRow,
  BCol,
  BAlert,
  BCard,
  BLink,
  BFormRow,
  BFormGroup,
  BFormInvalidFeedback,
  BFormSelectOption,
  BFormInput,
  BFormCheckbox,
  BFormSelect,
  BFormTextarea,
  BButton,
  BTooltip,
  BSpinner,
  BTabs,
  BTab,
  BModal,
  BBadge,
  BDropdown,
  BDropdownItem,
  BDropdownItemButton,
  BDropdownDivider,
  BDropdownText,
  BPopover,
  BProgress,
  BPagination,
  BAvatar,
  VBPopover,
  VBTooltip,
  VBToggle
} from 'bootstrap-vue';

Vue.component('BContainer', BContainer);
Vue.component('BRow', BRow);
Vue.component('BCol', BCol);
Vue.component('BAlert', BAlert);
Vue.component('BCard', BCard);
Vue.component('BLink', BLink);
Vue.component('BFormRow', BFormRow);
Vue.component('BFormGroup', BFormGroup);
Vue.component('BFormInvalidFeedback', BFormInvalidFeedback);
Vue.component('BFormSelectOption', BFormSelectOption);
Vue.component('BFormInput', BFormInput);
Vue.component('BFormCheckbox', BFormCheckbox);
Vue.component('BFormSelect', BFormSelect);
Vue.component('BFormTextarea', BFormTextarea);
Vue.component('BButton', BButton);
Vue.component('BTooltip', BTooltip);
Vue.component('BSpinner', BSpinner);
Vue.component('BTabs', BTabs);
Vue.component('BTab', BTab);
Vue.component('BModal', BModal);
Vue.component('BBadge', BBadge);
Vue.component('BDropdown', BDropdown);
Vue.component('BDropdownItem', BDropdownItem);
Vue.component('BDropdownItemButton', BDropdownItemButton);
Vue.component('BDropdownDivider', BDropdownDivider);
Vue.component('BDropdownText', BDropdownText);
Vue.component('BPopover', BPopover);
Vue.component('BProgress', BProgress);
Vue.component('BPagination', BPagination);
Vue.component('BAvatar', BAvatar);

Vue.directive('BPopover', VBPopover);
Vue.directive('BTooltip', VBTooltip);
Vue.directive('BToggle', VBToggle);
