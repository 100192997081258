import Cookie from 'js-cookie';
import extend from '@/utils/extendMounted';

export default async function({ app, store, route }) {
    await extend(app, {
        mounted() {
            const isLoggedIn = store.state.me.user.id;
            if (!isLoggedIn) {
                this.storeNonLoggedInUser();
            }
        },

        methods: {
            storeNonLoggedInUser() {
                const guestEmail = Cookie.get('guest_email');
                if (guestEmail) {
                    store.dispatch('user/checkEmail', { email: guestEmail })
                        .then((response) => {
                            store.commit('user/STORE_USER', response.data);
                        });
                }
            },
        },
    });
}
