export default {
    searchJobTitle(context, data) {
        const url = `v1/job-title-suggestions?keyword=${data}`;
        return this.$axios.$get(url);
    },

    getSearchPageMetaData(context, urlPath) {
        const url = `v1/search-job/meta-data?url=${urlPath}`;
        return this.$axios.$get(url);
    },
};
