export default function({ redirect, route, $axios }) {
    if (process.server) {
        $axios.onError((request, error) => {
            return redirect('/maintenance');
            // const code = parseInt(error.response && error.response.status);
            // if (code === 503) {
            //     return redirect('/maintenance');
            // }
        });

        const isMaintenance = true;
        if (isMaintenance && route.path !== '/maintenance') {
            return redirect('/maintenance');
        }

        // if (isMaintenance === false && route.path === '/maintenance') {
        //     return redirect('/');
        // }
    }
}
