export const state = () => ({
    user: {},
    apply_type: '', // 'seeker', 'new', 'guest'
    apply_uuid: '',
    is_applied: false,
    apply_step: 1,
    apply_steps_count: 1,

    is_apply_form_open: false,
    is_apply_form_offsite_open: false,
    is_basic_questions_open: false,
    is_onboarding_questions_open: false,
    is_custom_questions_open: false,
    is_signin_modal_open: false,

    is_resume_review: true,
    make_resume_default: true,
    custom_question_step: 1,
    is_custom_question_loading: false,
    questions: [],
});
