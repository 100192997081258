export default {
    getFooterLinks() {
        const url = 'v1/common/footer-links';
        return this.$axios.$get(url, { useCache: true });
    },

    getArticles() {
        const url = 'v1/common/articles';
        return this.$axios.$get(url);
    },

    getCompaniesLogo() {
        const url = 'v1/common/companies-logo';
        return this.$axios.$get(url);
    },

    getSalaryEstimation(context, data) {
        const url = 'v1/salary-estimation';
        return this.$axios.$post(url, data);
    },
};
