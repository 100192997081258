export default {
    getAppliedJobs(context, query) {
        let url = 'v1/me/applied-jobs';
        if (query) {
            url += `?query=${query}`;
        }
        return this.$axios.$get(url).then((response) => {
            context.commit('STORE_APPLIED_JOB', response);
            return response;
        });
    },

    completeApply(context, applyId) {
        const url = `v1/me/complete-apply/${applyId}`;
        return this.$axios.$put(url);
    },
};
