import { render, staticRenderFns } from "./PromptModal.vue?vue&type=template&id=44625040&scoped=true&"
import script from "./PromptModal.vue?vue&type=script&lang=js&"
export * from "./PromptModal.vue?vue&type=script&lang=js&"
import style0 from "./PromptModal.vue?vue&type=style&index=0&id=44625040&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "44625040",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {NuxtDynamic: require('/var/www/seeker/main/node_modules/@blokwise/dynamic/src/components/Dynamic.vue').default})
