export default {
    getEducationArticles(context, payload) {
        const url = 'v1/education/articles';

        return this.$axios.$post(url, payload);
    },

    postEducationEnquiry(context, data) {
        const url = 'v1/education/enquiry';

        return this.$axios.$post(url, data);
    },

    postEducationArticleImpression(context, uuid) {
        const url = 'v1/education/article/impression';
        const payload = {
            education_article_uuid: uuid,
        };

        return this.$axios.$post(url, payload);
    },
};
