import Cookie from 'js-cookie';

export default {
    DISABLE_BOTTOM_DIALOGUE(state) {
        state.showBottomDialogue = 'false';
        Cookie.set('rw_show_bottom_dialogue', 'false', { expires: 7 });
    },

    INCREASE_JOB_VIEW_COUNT(state) {
        let jobViewCount = Cookie.get('rw_job_view_count') || 0;
        jobViewCount = parseInt(jobViewCount) + 1;
        Cookie.set('rw_job_view_count', jobViewCount, { expires: 7 });
        state.jobViewCount = jobViewCount;
    },

    SET_STATE_BOTTOM_DIALOGUE(state, value) {
        state.showBottomDialogue = value;
    },

    SET_JOB_VIEW_COUNT(state, value) {
        state.jobViewCount = value;
    },
};
