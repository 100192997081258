export default {
    STORE_JOB(state, data) {
        state.job = data;
    },

    UPDATE_JOB(state, data) {
        state.job[data.key] = data.value;
    },

    DISABLE_JOB_APPLY(state) {
        state.is_apply_enabled = false;
    },

    SHOW_ORIGINAL_POSTED_DATE(state) {
        state.show_original_posted_date = true;
    },
};
