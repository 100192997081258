export default {
    STORE_USER(state, data) {
        state.user = data;
    },

    CLEAR_USER(state) {
        state.user = {};
        state.attributeOptions = [];
        state.education = [];
        state.workExperience = [];
        state.workLocations = [];
        state.desiredOccupations = [];
        state.savedJobs = [];
        state.saveJobAfterLogin = '';
        state.saveAttributionOptionAfterLogin = '';
        state.isSearchSaveModalOpen = false;
        state.resumes = [];
        state.isResumeQualityFeedbackModalOpen = false;
        state.scoringData = {};
        state.scoringMatrixData = {};
        state.discoveryNav = {
            init: true,
            discover: 0,
            saved_jobs: 0,
            saved_searches: 0,
        };
    },

    UPDATE_USER_AVATAR(state, data) {
        state.user.avatar = data;
    },

    UPDATE_USER_PROFILE_SCORE(state, data) {
        state.user.profile_completion = data;
    },

    UPDATE_USER_EQUAL_OPPORTUNITY_TAGS(state, data) {
        state.user.equalOpportunityTags = data;
    },
};
