export default {
    STORE(state, data) {
        if (state.server_id === '') {
            state.app_version = data.app_version;
            state.server_id = data.server_id;
            state.country_code = data.http_cf_ipcountry;
        }
    },

    STORE_DEVICE_SIZE(state, data) { // TODO deprecated, do not use
        state.isMobile = data.matchMedia('(max-width: 767px)').matches;
    },

    STORE_ACTIVE_TILE(state, data) {
        state.activeTile = data;
    },

    OPEN_MOBILE_MENU(state) {
        state.isMobileMenuOpen = true;
    },

    CLOSE_MOBILE_MENU(state) {
        state.isMobileMenuOpen = false;
    },

    OPEN_SIDEBAR(state, lastClicked) {
        state.isSidebarOpen = true;
        state.sidebarLastClicked = lastClicked;
    },

    CLOSE_SIDEBAR(state) {
        state.isSidebarOpen = false;
        state.sidebarLastClicked = '';
    },

    OPEN_NOTIFICATIONBAR(state) {
        state.isNotificationBarOpen = true;
    },

    CLOSE_NOTIFICATIONBAR(state) {
        state.isNotificationBarOpen = false;
    },

    SAVE_FIRST_DATA_LAYER(state) {
        state.isFirstDataLayerSaved = true;
    },
};
