export default ({ req, route }, inject) => {
    let hostname = '';
    let authOrigin = '';

    if (process.server) {
        hostname = req.headers.host.split(':')[0];
        if (req.headers['x-site']) {
            hostname = req.headers['x-site'];
        }
    }

    if (process.browser) {
        hostname = window.location.hostname;
    }

    if (route.name === 'one-auth' && route.query.hostname) {
        authOrigin = route.query.hostname;
        hostname = route.query.hostname;
    }

    // set default hostname if it's localhost
    const defaultHostname = process.env.DEFAULT_HOSTNAME ? process.env.DEFAULT_HOSTNAME : 'www.careerone.com.au';
    hostname = hostname === 'localhost' ? defaultHostname : hostname;
    inject('hostname', hostname);
    inject('authOrigin', authOrigin || hostname);
};
