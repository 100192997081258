import Cookie from 'js-cookie';
import { cacheAdapterEnhancer } from 'axios-extensions';
import LRUCache from 'lru-cache';
import datalayer from '@/utils/datalayer';
import { getTokenFromReq, pushEvent } from '@/utils/helpers';

const ONE_HOUR = 1000 * 60 * 60;
const defaultCache = new LRUCache({ maxAge: ONE_HOUR });

export default ({ app, $axios, req, store }, inject) => {
    // axios via @nuxt/axios module
    $axios.defaults.adapter = cacheAdapterEnhancer(
        $axios.defaults.adapter,
        { enabledByDefault: false, cacheFlag: 'useCache', defaultCache },
    );

    $axios.defaults.headers.common['Content-Type'] = 'application/json';
    $axios.defaults.headers.common.Accept = 'application/json';
    $axios.defaults.headers.common['site-code'] = app.$siteCode;
    $axios.defaults.headers.common['platform-code'] = app.$platformCode;

    $axios.onRequest((config) => {
        let token = Cookie.get(process.env.COOKIE_TOKEN_NAME);
        if (process.server) {
            token = getTokenFromReq(req);
        }

        if (token) {
            config.headers.common.Authorization = `Bearer ${token}`;
        }

        // fires event if seeker profile updated
        const mePathsMatch = ['/me/attributes/',
            'v1/me/cover-letters',
            'v1/me/desired-occupations',
            'v1/me/education',
            'v1/me/email',
            'v1/me/delete-account',
            'v1/me/avatar',
            'v1/me/resumes',
            'v1/me/subscriptions',
            'v1/me/work-experience',
            'v1/me/work-locations'];
        const mePathsExact = ['v1/me'];
        if (config.url && config.method !== 'get' && (mePathsExact.includes(v => config.url === v) || mePathsMatch.includes(v => config.url.includes(v)))) {
            const hasRecentUpdate = Cookie.get('last_profile_update');
            if (!hasRecentUpdate) {
                Cookie.set('last_profile_update', 1, { expires: 1 });

                const data = {
                    event: 'profile_updated',
                    seeker_id: store.state.me.user.id,
                    seeker_hash: store.state.me.user.email_hash,
                };
                datalayer(data);
                pushEvent(data);
            }
        }
    });
};
