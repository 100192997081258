export default {
    getProfileTasks(context, data) {
        let url = 'v1/me/profile-tasks';
        if (data) {
            const query = Object.keys(data).map(key => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`).join('&');
            if (query) {
                url += `?${query}`;
            }
        }
        return this.$axios.$get(url).then((response) => {
            context.commit('PROFILE_TASKS', response.data);
            context.commit('UPDATE_USER_PROFILE_SCORE', response.seeker.profile_completion);
            return response;
        });
    },

    reviseProfileTask(context, id) {
        const url = `v1/me/profile-task/${id}/revise`;
        return this.$axios.$put(url);
    },

    dismissProfileTask(context, id) {
        const url = `v1/me/profile-task/${id}/dismiss`;
        return this.$axios.$put(url);
    },

    completeProfileTask(context, id) {
        const url = `v1/me/profile-task/${id}/complete`;
        return this.$axios.$put(url);
    },

    pauseProfileTask(context, id) {
        const url = `v1/me/profile-task/${id}/pause`;
        return this.$axios.$put(url);
    },
};
