import has from 'lodash/has';

export default ({ query, store, res }) => {
    if (process.server) {
        const queryKey = 'onesignal_push_id';

        if (query[queryKey]) {
            if (has(store.state, 'me.user.id')) {
                store.dispatch('me/storeOnesignal', { push_id: query[queryKey] }).catch((error) => {
                    console.log(error);
                    console.log(error.response);
                });
            } else {
                res.setHeader('Set-Cookie', [`${process.env.COOKIE_ONESIGNAL}=${query[queryKey]}`]);
            }
        }
    }
};
