import Cookie from 'js-cookie';
import { getTokenFromReq } from '@/utils/helpers';

export default ({
    req, redirect, store, route,
}) => {
    // default redirect
    const redirectRoute = {
        name: 'auth',
        query: {
            redirect: route.path,
        },
    };

    // Redirect from SSR side
    if (process.server) {
        const token = getTokenFromReq(req);

        if (!token) {
            return redirect(redirectRoute);
        }

        return store.dispatch('me/getMe').catch(() => {
            return redirect(redirectRoute);
        });
    }

    // Redirect from client side
    if (process.browser) {
        if (!Cookie.get(process.env.COOKIE_TOKEN_NAME)) {
            return redirect(redirectRoute);
        }
    }
};
