import Vue from 'vue';

Vue.prototype.$isFacebookApp = () => {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;
    return (userAgent.includes('FBAN')) || (userAgent.includes('FBAV'));
};

Vue.prototype.$isAndroidOS = () => {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;
    // // Windows Phone must come first because its UA also contains "Android"
    // if (/windows phone/i.test(userAgent)) {
    //     return 'Windows Phone';
    // }
    if (/android/i.test(userAgent)) {
        return true;
    }
    return false;
    // iOS detection from: http://stackoverflow.com/a/9039885/177710
    // if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
    //     return 'iOS';
    // }
    // return 'unknown';
};
