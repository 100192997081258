/* eslint no-shadow: "off" */

export const state = () => ({
    categories: [],
    workTypes: [],
    brand: {},
    brandArticles: [],
    brandArticleDetails: [],
    searchFilterCount: 0,
    emitSearchFilterChanged: 0,
    searchTag: 0,
    searchPayload: {
        search_filters: {},
    },
    pageMetaData: {},
    initSearchCriteria: {
        search: '',
        search_keywords: '',
        categories: [],
        location: {},
        parsed: {},
        pay_min: {},
        pay_max: {},
        ad_type: [],
        posted_within_days: {
            days: 0,
            value: 'Any time',
            is_selected: true,
            count: '',
        },
        skills: [],
        keywords: [],
        brands: [],
        sector: [],
        industry: [],
        company_size: [],
        job_mode: [],
        job_title: [],
        job_type: [],
        contract_type: [],
        career_level: [],
        perks: [],
        work_authorisation: [],
        education_level: [],
        languages: [],
        certifications: [],
        parsed_filter: 0,
        include_surrounding_location: true,
        equal_opportunity_tags: [],
        display_pay_type: 1,
    },
    adType: [
        {
            id: 1,
            name: 'Private listings',
            slug: 'private-listings',
            is_selected: false,
            count: '',
        },
        {
            id: 2,
            name: 'Recruiters',
            slug: 'recruiters',
            is_selected: false,
            count: '',
        },
    ],
    postedWithIn: [
        {
            days: 1,
            value: 'Today',
            label: 'Posted today',
            is_selected: false,
            count: '',
        },
        {
            days: 7,
            value: '7 days',
            label: 'Last 7 days',
            is_selected: false,
            count: '',
        },
        {
            days: 30,
            value: '30 days',
            label: 'Last 30 days',
            is_selected: false,
            count: '',
        },
    ],
    searchFilterAttributes: [],
    searchFilterTags: [],
    searchbarTagsHide: true,
    activeSearchFilter: false,
    jobAlertModeNew: false,
    jobAlertModeUpdate: false,
    jobAlertUpdateId: null,
    salaries: [],
    searchPageTemplate: '',
    searchLayoutTemplate: 'default',
    initSearchData: false,
    isSavedSearchFlyEnabled: true,
    searchBlurbText: true,
    showSearchBar: true,
    availableEeoTags: [],
    isSearchSummary: true,
    isSearchFullscreenMode: false,
    activateSearchAnimate: false,
});
