export default {
    STORE_JOB_EVENTS(state, data) {
        state.jobEvents = data;
    },

    PUT_JOB_EVENT(state, data) {
        const index = state.jobEvents.findIndex(o => o.job_uuid === data.job);

        if (index >= 0) {
            const status = state.jobEvents[index][data.status];
            if (data.status === 'is_viewed') {
                state.jobEvents[index].is_viewed = true;
            } else {
                state.jobEvents[index][data.status] = !status;
            }
        } else {
            const newJobEvent = {
                job_uuid: data.job,
                is_applied: false,
                is_viewed: false,
                is_saved: false,
                is_hidden: false,
            };
            if (data.status === 'is_applied' || data.status === 'is_clicked') {
                newJobEvent.is_applied = true;
            } else if (data.status === 'is_viewed') {
                newJobEvent.is_viewed = true;
            } else if (data.status === 'is_saved') {
                newJobEvent.is_saved = true;
            } else if (data.status === 'is_hidden') {
                newJobEvent.is_hidden = true;
            }
            state.jobEvents.push(newJobEvent);
        }
    },
};
