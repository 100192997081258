
    import { mapState, mapMutations } from 'vuex';

    import ResumeQualityFeedbackModalDesktop from '@/components/Seeker/ResumeQualityFeedback/ResumeQualityFeedbackModalDesktop';
    import ResumeQualityFeedbackModalMobile from '@/components/Seeker/ResumeQualityFeedback/ResumeQualityFeedbackModalMobile';
    import SidebarDefault from '@/components/Sidebar/SidebarDefault';
    import SidebarMini from '@/components/Sidebar/SidebarMini';

    export default {
        name: 'Sidebar',

        components: {
            SidebarDefault,
            SidebarMini,
            ResumeQualityFeedbackModalDesktop,
            ResumeQualityFeedbackModalMobile,
        },

        data() {
            return {
                sidebarEl: '',
                sidebarDefaultEl: '',
                sidebarMiniEl: '',
                sidebarOverlayEl: '',
            };
        },

        computed: {
            ...mapState({
                $_isSidebarOpen: state => state.appInfo.isSidebarOpen,
                $_isResumeQualityFeedbackModalOpen: state => state.me.isResumeQualityFeedbackModalOpen,
            }),
        },

        watch: {
            $_isSidebarOpen(value) {
                this.animateSidebar(value);
            },
        },

        mounted() {
            this.sidebarEl = this.$el;
            this.sidebarDefaultEl = this.$refs.sidebarDefault.$el;
            this.sidebarMiniEl = this.$refs.sidebarMini.$el;

            if (this.$_isSidebarOpen) {
                this.animateSidebar(this.$_isSidebarOpen);
            }
        },

        beforeDestroy() {
            this.animateSidebarOverlay('fadeOut');
        },

        methods: {
            ...mapMutations('appInfo', {
                $_closeSidebar: 'CLOSE_SIDEBAR',
            }),

            ...mapMutations('me', {
                $_CLOSE_RESUME_QUALITY_FEEDBACK_MODAL: 'CLOSE_RESUME_QUALITY_FEEDBACK_MODAL',
            }),

            closeFeedbackModal() {
                this.$_CLOSE_RESUME_QUALITY_FEEDBACK_MODAL();
            },

            onClickOutside() {
                this.$_closeSidebar();
            },

            animateSidebar(isOpen) {
                // hack to make the default sidebar scroll top
                // because it has display none it lost position on DOM
                if (isOpen) {
                    const el = this.sidebarDefaultEl.querySelector('.aside-default__content');
                    el.parentElement.replaceChild(el, el);
                    this.initSidebarOverlay();
                    this.animateSidebarOverlay('fadeIn');
                }

                if (window.innerWidth > 1200) {
                    this.slide(isOpen);
                } else {
                    this.push(isOpen);
                }
            },

            /* eslint-disable */
            push(isOpen) {
                this.$velocity(this.sidebarEl, 'stop', true);

                const width = this.$mq === 'sm' ? '100%' : '400px';

                const sidebarOpt = {
                    width,
                    translateX: [0, '-100%'],
                };

                let sidebarShow = this.sidebarDefaultEl;

                if (isOpen) {
                    this.sidebarDefaultEl.querySelector('.aside-default__content').scrollTop = 0;
                }

                if (!isOpen) {
                    sidebarOpt.translateX = ['-100%', 0];
                    this.animateSidebarOverlay('fadeOut');
                }

                // animate
                this.$velocity(sidebarShow, 'fadeOut', { easing: 'easeInOutQuart', duration: 100 });
                this.$velocity(this.sidebarEl, sidebarOpt, { delay: 90, easing: 'easeInOutQuart', duration: 300 });
                this.$velocity(sidebarShow, 'fadeIn', { display: 'flex', delay: 300, easing: 'easeInOutQuart', duration: 300 });
            },

            slide(isOpen) {
                const sidebarOpt = {
                    width: ['400px', '80px'],
                    translateX: [0, 0],
                };
                let sidebarHide = this.sidebarMiniEl;
                let sidebarShow = this.sidebarDefaultEl;

                if (!isOpen) {
                    sidebarOpt.width = ['80px', '400px'];
                    sidebarHide = this.sidebarDefaultEl;
                    sidebarShow = this.sidebarMiniEl;
                    this.animateSidebarOverlay('fadeOut');
                }

                // cancel the animation
                this.$velocity(this.sidebarEl, 'stop', true);

                // animate
                this.$velocity(sidebarHide, 'fadeOut', { easing: 'easeInOutQuart', duration: 100 });
                this.$velocity(this.sidebarEl, sidebarOpt, { delay: 90, easing: 'easeInOutQuart', duration: 300 });
                this.$velocity(sidebarShow, 'fadeIn', { display: 'flex', delay: 300, easing: 'easeInOutQuart', duration: 300 });
            },

            onWindowResize() {
                const windowWidth = window.innerWidth;
                const sidebarProp = {
                    width: '100vw',
                    transform: this.$_isSidebarOpen ? 'translateX(0)' : 'translateX(-100%)',
                };
                const sidebarDefaultProp = {
                    display: 'flex',
                    opacity: '1',
                };
                const sidebarMiniProp = {
                    display: 'none',
                };

                if (windowWidth >= 1200) {
                    sidebarProp.width = this.$_isSidebarOpen ? '400px' : '80px';
                    sidebarProp.transform = 'translateX(0)';

                    sidebarDefaultProp.display = this.$_isSidebarOpen ? 'flex' : 'none';
                    sidebarDefaultProp.opacity = this.$_isSidebarOpen ? '1' : '0';

                    sidebarMiniProp.display = this.$_isSidebarOpen ? 'none' : 'flex';
                } else if (windowWidth >= 576) {
                    sidebarProp.width = '400px';
                    sidebarProp.transform = this.$_isSidebarOpen ? 'translateX(0)' : 'translateX(-100%)';

                    sidebarDefaultProp.display = 'flex';
                    sidebarDefaultProp.opacity = '1';

                    sidebarMiniProp.display = 'none';
                }

                this.sidebarEl.style.width = sidebarProp.width;
                this.sidebarEl.style.transform = sidebarProp.transform;

                this.sidebarDefaultEl.style.display = sidebarDefaultProp.display;
                this.sidebarDefaultEl.style.opacity = sidebarDefaultProp.opacity;

                this.sidebarMiniEl.style.display = sidebarMiniProp.display;
            },

            initSidebarOverlay() {
                const overlay = document.createElement('div');
                overlay.id = 'sidebarOverlay';
                overlay.style.position = 'fixed';
                overlay.style.top = '0';
                overlay.style.left = '0';
                overlay.style.width = '100%';
                overlay.style.height = '100%';
                overlay.style.backgroundColor = 'rgba(0, 0, 0, .3)';
                overlay.style.zIndex = '10034';
                const body = document.getElementsByTagName('body')[0];
                body.appendChild(overlay);
                this.sidebarOverlayEl = overlay;
            },

            animateSidebarOverlay(fade) {
                this.$velocity(
                    this.sidebarOverlayEl,
                    fade,
                    {
                        easing: 'easeInOutQuart',
                        duration: 700,
                        complete: () => {
                            const body = document.getElementsByTagName('body')[0];
                            if (fade === 'fadeOut') {
                                body.classList.remove('overflow-hidden');
                                this.sidebarOverlayEl.remove();
                            } else if (fade === 'fadeIn') {
                                body.classList.add('overflow-hidden');
                            }
                        },
                    });
            },
        },
    };
