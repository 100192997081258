export default {
    applyToJob(context, { jobUuid, data }) {
        const url = `v1/jobs/${jobUuid}/apply`;
        return this.$axios.$put(url, data);
    },

    applyToJobAsGuest(context, { jobUuid, data }) {
        const url = `v1/jobs/${jobUuid}/guest-apply`;
        let input = data;

        if (data.resume_file instanceof File) {
            input = new FormData();

            Object.entries(data).forEach((o) => {
                input.append(o[0], o[1]);
            });
        }

        return this.$axios.$post(url, input);
    },

    getJobQuestions(context, { jobUuid, applicationUuId }) {
        const url = `v1/jobs/${jobUuid}/questions?application_uuid=${applicationUuId}`;
        return this.$axios.$get(url).then((response) => {
            context.commit('STORE_JOB_QUESTIONS', response.data);
            return response;
        });
    },
};
