export default {
    getEducations(context, data) {
        const url = 'v1/me/education';
        return this.$axios.$get(url, data);
    },

    storeEducation(context, data) {
        const url = 'v1/me/education';
        return this.$axios.$post(url, data);
    },

    updateEducation(context, { educationId, data }) {
        const url = `v1/me/education/${educationId}`;
        return this.$axios.$put(url, data);
    },

    destroyEducation(context, educationId) {
        const url = `v1/me/education/${educationId}`;
        return this.$axios.$delete(url);
    },
};
