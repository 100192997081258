import has from 'lodash/has';

export default {
    applyEmail(state) {
        return has(state, 'user.email') ? state.user.email : '';
    },

    isApplyTypeSeeker(state) {
        return state.apply_type === 'seeker';
    },

    isApplyTypeNew(state) {
        return state.apply_type === 'new';
    },

    isApplyTypeGuest(state) {
        return state.apply_type === 'guest';
    },
};
