
    import { mapState } from 'vuex';
    import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
    import {
        faQuestionCircle as farQuestionCircle,
    } from '@fortawesome/pro-regular-svg-icons/faQuestionCircle';

    import {
        faTimes as falTimes,
    } from '@fortawesome/pro-light-svg-icons/faTimes';

    import ResumeQualityFeedbackIssues from '@/components/Seeker/ResumeQualityFeedback/ResumeQualityFeedbackIssues';
    import ResumeQualityFeedbackResume from '@/components/Seeker/ResumeQualityFeedback/ResumeQualityFeedbackResume';
    import ResumeQualityFeedbackCta from '@/components/Seeker/ResumeQualityFeedback/ResumeQualityFeedbackCta';
    import ResumeQualityFeedback100 from '@/components/Seeker/ResumeQualityFeedback/ResumeQualityFeedback100';

    export default {
        name: 'ResumeQualityFeedbackModalDesktop',

        components: {
            ResumeQualityFeedbackIssues,
            ResumeQualityFeedbackResume,
            ResumeQualityFeedbackCta,
            ResumeQualityFeedback100,
            FontAwesomeIcon,
        },

        props: {
            resume: {
                type: Object,
                default: () => {
                },
            },
        },

        data() {
            return {
                isOpen: false,
                icons: {
                    falTimes,
                    farQuestionCircle,
                },
            };
        },

        computed: {
            ...mapState({
                $_global: state => state.global,
                $_meResumes: state => state.me.resumes,
            }),

            currentResume() {
                if (this.resume) {
                    return this.resume;
                } else {
                    return this.$_meResumes.slice(-1)[0];
                }
            },
        },

        created() {
            this.openModal();

            const unregisterRouterGuard = this.$router.beforeEach((to, from, next) => {
                this.back();

                next(false);
            });

            this.$once('hook:destroyed', () => {
                unregisterRouterGuard();
            });
        },

        beforeDestroy() {
            this.closeModal();
        },

        methods: {
            back() {
                this.closeModal();
            },

            close() {
                this.$emit('close');
            },

            openModal() {
                this.isOpen = true;
            },

            closeModal() {
                this.isOpen = false;
            },
        },
    };
