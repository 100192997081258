
    import Logo from '@/components/Partials/Logo';
    import HtmlHeader from '@/components/Partials/HtmlHeader';

    export default {
        name: 'JobPreview',

        components: {
            Logo,
        },

        mixins: [HtmlHeader],

        head() {
            return this.head;
        },
    };
