
    import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';
    import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
    import {
        faSparkles as farSparkles,
    } from '@fortawesome/pro-regular-svg-icons/faSparkles';

    import {
        faChevronRight as farChevronRight,
    } from '@fortawesome/pro-regular-svg-icons/faChevronRight';

    import SeekerAvatarProgressbar from '@/components/Seeker/SeekerAvatarProgressbar';
    import PoweredByC1 from '@/components/Partials/PoweredByC1';

    export default {
        name: 'SidebarStrengthDetailed',

        components: {
            SeekerAvatarProgressbar,
            FontAwesomeIcon,
            PoweredByC1,
        },

        data() {
            return {
                animate: false,
                icons: {
                    farSparkles,
                    farChevronRight,
                },
            };
        },

        computed: {
            ...mapState({
                $_profileScore: state => state.me.user.profile_completion,
                $_profileTasks: state => state.me.profileTasks,
                $_me: state => state.me.user,
            }),

            ...mapGetters('me', {
                $_getProfileSections: 'getProfileSections',
                $_getCompletedTaskCount: 'getCompletedTaskCount',
                $_getTotalTaskCount: 'getTotalTaskCount',
            }),

            getProfileScore() {
                return this.$_profileScore;
            },

            colorByScore() {
                let className;
                if (this.getProfileScore <= 33) {
                    className = 'is-low';
                } else if (this.getProfileScore > 66) {
                    className = 'is-high';
                } else {
                    className = 'is-medium';
                }
                return className;
            },
        },

        mounted() {
            if (this.$_profileTasks.length <= 0) {
                this.$_getProfileTasks();
            }
        },

        methods: {
            ...mapMutations('appInfo', {
                $_closeSidebar: 'CLOSE_SIDEBAR',
            }),

            ...mapActions('me', {
                $_getProfileTasks: 'getProfileTasks',
            }),

            onMouseover() {
                if (['xl', 'inf'].includes(this.$mq)) {
                    this.animate = true;
                }
            },

            onMouseleave() {
                this.animate = false;
            },
        },
    };
