import numeral from 'numeral';

export default class JobPay {
    $annualToHourDivider = 1976;

    $payMaxAnnualLimit = 350000;

    $payMaxHourLimit = 150;

    $isPayEstimated = false;

    $payType = 'annual_salary';

    $workType = '';

    $contractType = '';

    $isPayHidden = false;

    $isPayAboveAvg = false;

    constructor(
        payMin,
        payMax,
        payType,
        convertToHour = false,
        isPayEstimated = false,
        workType = '',
        contractType = '',
        isPayHidden = false,
        isAboveAvg = false,
    ) {
        this.$payMin = Math.round(parseFloat(payMin));
        this.$payMax = Math.round(parseFloat(payMax));
        this.$isPayTypeAnnual = (payType === 'annual_salary');
        this.$payType = payType || 'annual_salary';
        this.$convertToHour = convertToHour;
        this.$isPayEstimated = isPayEstimated;
        this.$workType = workType;
        this.$contractType = contractType;
        this.$isPayHidden = isPayHidden;
        this.$isPayAboveAvg = isAboveAvg;
    }

    get payMin() {
        if (this.$isPayTypeAnnual) {
            return this.$convertToHour ? Math.round(this.$payMin / this.$annualToHourDivider) : this.$payMin;
        }

        return Math.round(this.$payMin);
    }

    get payMinLabel() {
        let output = `${numeral(this.payMin).format('$0,0')}`;

        if (!this.$isPayTypeAnnual) {
            output += this.suffix;
        }

        return output;
    }

    get suffix() {
        if (this.$payType === 'per_day') {
            return '/day';
        }
        if (this.$payType === 'per_month') {
            return '/month';
        }
        return '/hr';
    }

    get payMinShortLabel() {
        let output = `${numeral(this.payMin).format('$0a')}`;

        if (!this.$isPayTypeAnnual || this.$convertToHour) {
            output += this.suffix;
        }

        return output;
    }

    get payMax() {
        if (this.$isPayTypeAnnual) {
            if (this.$convertToHour) {
                return Math.round(this.$payMax / this.$annualToHourDivider);
            } else {
                return this.$payMax;
            }
        }

        return Math.round(this.$payMax);
    }

    get payMaxLabel() {
        let output = `${numeral(this.payMax).format('$0,0')}`;

        if (this.$isPayTypeAnnual && this.payMax > this.$payMaxAnnualLimit) {
            output = `${numeral(this.$payMaxAnnualLimit).format('$0,0')}+`;
        }

        if (!this.$isPayTypeAnnual) {
            if (this.$payType === 'per_hour' && this.payMax >= this.$payMaxHourLimit) {
                output = `${numeral(this.$payMaxHourLimit).format('$0,0')}`;
                output += `${this.suffix}+`;
            } else {
                output += this.suffix;
            }
        }

        return output;
    }

    get payMaxShortLabel() {
        let output = `${numeral(this.payMax).format('$0a')}`;

        if (this.$isPayTypeAnnual && this.payMax > this.$payMaxAnnualLimit) {
            output = `${numeral(this.$payMaxAnnualLimit).format('$0a')}+`;
        }

        if (!this.$isPayTypeAnnual || this.$convertToHour) {
            if (this.$payType === 'per_hour' && this.payMax >= this.$payMaxHourLimit) {
                output = `${numeral(this.$payMaxHourLimit).format('$0a')}`;
                output += `${this.suffix}+`;
            } else {
                output += this.suffix;
            }
        }

        return output;
    }

    get payMinMaxLabel() {
        const prefix = this.$isPayEstimated ? `${this.payMinLabel} ~` : '';
        const suffix = this.$isPayEstimated && (this.isCasualHoliday || this.isPartTime) && this.isContract ? 'pro rata' : '';

        if (this.payMin === 0 && this.payMax === 0) {
            return 'Undisclosed';
        }

        if (this.payMin === this.payMax) {
            return `${this.payMaxLabel} ${suffix}`;
        }

        if (this.payMin === 0) {
            return `Up to ${this.payMaxLabel} ${suffix}`;
        }

        let output = this.$isPayEstimated ? this.payMaxLabel : `${this.payMinLabel} - ${this.payMaxLabel}`;

        if (!this.$isPayTypeAnnual) {
            output = output.replace(this.suffix, '');
        }

        return `${prefix} ${output} ${suffix}`;
    }

    get payMinMaxShortLabel() {
        const prefix = this.$isPayEstimated ? `${this.payMinShortLabel} -` : '';
        const suffix = this.$isPayEstimated && (this.isCasualHoliday || this.isPartTime) && this.isContract ? 'pro rata' : '';

        if (this.payMin === 0 && this.payMax === 0) {
            return 'Undisclosed';
        }

        if (this.payMin === this.payMax) {
            return `${this.payMaxShortLabel} ${suffix}`;
        }

        if (this.payMin === 0) {
            return `Up to ${this.payMaxShortLabel} ${suffix}`;
        }

        if (this.$isPayTypeAnnual && this.payMax > this.$payMaxAnnualLimit) {
            return this.payMaxShortLabel;
        }

        let output = this.$isPayEstimated ? this.payMaxShortLabel : `${this.payMinShortLabel} - ${this.payMaxShortLabel}`;

        if (!this.$isPayTypeAnnual) {
            output = output.replace(this.suffix, '');
        }

        return `${prefix} ${output} ${suffix}`;
    }

    get payMinMaxShortLabelRange() {
        const suffix = this.$isPayEstimated && (this.isCasualHoliday || this.isPartTime) && this.isContract ? 'pro rata' : '';

        if (this.payMin === 0 && this.payMax === 0) {
            return 'Undisclosed';
        }

        if (this.payMin === this.payMax) {
            return `${this.payMaxShortLabel} ${suffix}`;
        }

        if (this.payMin === 0) {
            return `Up to ${this.payMaxShortLabel} ${suffix}`;
        }
        if (this.payMin > this.$payMaxAnnualLimit) {
            return `${numeral(this.$payMaxAnnualLimit).format('$0a')}+`;
        }
        let output = `${this.payMinShortLabel} - ${this.payMaxShortLabel}`;

        if (!this.$isPayTypeAnnual) {
            output = output.replace(this.suffix, '');
        }

        return `${output} ${suffix}`;
    }

    get isPartTime() {
        return this.$workType === 'Part Time';
    }

    get isCasualHoliday() {
        return this.$workType === 'Casual/Holiday';
    }

    get isFullTime() {
        return this.$workType === 'Full Time';
    }

    get isContract() {
        return this.$contractType === 'Contract';
    }
}
