
    import { mapState, mapMutations, mapGetters } from 'vuex';

    export default {
        data() {
            return {};
        },

        computed: {
            ...mapState({
                $_me: state => state.me.user,
                $_appInfo: state => state.appInfo,
                $_eventTracking: state => state.eventTracking,
            }),

            head() {
                return {
                    title: `Job Search - Find Your Next Career Opportunity | ${this.$siteName}`,
                    link: [
                        { hid: 'manifest', rel: 'manifest', href: this.$manifestUrl }, // href value is set via manifest.js plugin
                        { hid: 'canonical', rel: 'canonical', href: `${this.$staticRoutes.careeroneUrl}${this.$route.fullPath.substring(1)}` },
                        { rel: 'icon', type: 'image/x-icon', href: this.$favicon },
                        { rel: 'preload', href: this.$fontUrl, as: 'style' },
                        { rel: 'stylesheet', href: this.$fontUrl },
                        { rel: 'preload', href: `https://www.careerone.com.au/media/site-variables.css?domain=${this.$hostname}&v=11072023`, as: 'style' },
                        { rel: 'stylesheet', href: `https://www.careerone.com.au/media/site-variables.css?domain=${this.$hostname}&v=11072023` },
                    ],
                    meta: [
                        {
                            hid: 'description',
                            name: 'description',
                            content: `${this.$siteName} lets you search thousands of Australian jobs to find your dream job. With tools for job search, resume-writing &amp; more, ${this.$siteName} will help you be found!`,
                        },
                        { hid: 'og:url', property: 'og:url', content: `${this.$siteUrl}${this.$route.fullPath.substring(1)}` },
                        { hid: 'og:title', property: 'og:title', content: `Job Search, Upload your Resume, Find employment - ${this.$siteName}` },
                        { hid: 'og:image', property: 'og:image', content: this.$siteBanner },
                        {
                            hid: 'og:description',
                            property: 'og:description',
                            content: `Search 1000's of Australian jobs. Find your dream job or let our expert recruiters find it for you. Find jobs. Be found. ${this.$siteName} Recruit.`,
                        },
                    ],
                    htmlAttrs: {
                        class: [
                            this.$isC1Ios ? 'ios' : '',
                        ],
                    },
                    script: this.scripts(),
                };
            },

            ...mapGetters({
                $_currentAppUrl: 'currentAppUrl',
            }),
        },

        methods: {
            ...mapMutations('appInfo', {
                $_STORE_APP_INFO: 'STORE',
            }),

            newReliceBrowserAgentScript() {
                let script = {};

                if (process.env.APP_ENV === 'production') {
                    script = {
                        hid: 'newrelic-browser-agent',
                        src: '/js/newrelic-browser-agent.js',
                        defer: true,
                        type: 'text/javascript',
                        charset: 'UTF-8',
                    };
                }

                return script;
            },

            scripts() {
                let firstDatalayer = {};

                if (typeof window === 'undefined') {
                    let firstDataLayerFields = {
                        app_version: this.$_appInfo.app_version,
                        server_id: this.$_appInfo.server_id,
                        user_ip_country_code: this.$_eventTracking.user_ip_country_code,
                        platform: this.$_eventTracking.platform,
                        app_id: this.$_eventTracking.app_id,
                        site: this.$siteCode,
                        platform_code: this.$platformCode,
                    };

                    if (this.$_me.id) {
                        firstDataLayerFields.seeker_id = this.$_me.id;
                        firstDataLayerFields.seeker_hash = this.$_me.email_hash;
                    }

                    firstDataLayerFields = JSON.stringify(firstDataLayerFields);

                    firstDatalayer = {
                        innerHTML: 'window.dataLayer = window.dataLayer || []; \n' +
                            `window.dataLayer.push(${firstDataLayerFields});`,
                    };
                }

                return [
                    // this.newReliceBrowserAgentScript(),
                    firstDatalayer,
                    {
                        innerHTML: 'window.originalLocation = document.location.protocol + \'//\' +\n' +
                            '                    document.location.hostname +\n' +
                            '                    document.location.pathname +\n' +
                            '                    document.location.search\n' +
                            ';',
                    },
                    {
                        innerHTML: '(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({\'gtm.start\':\n' +
                            '            new Date().getTime(),event:\'gtm.js\'});var f=d.getElementsByTagName(s)[0],\n' +
                            '            j=d.createElement(s),dl=l!=\'dataLayer\'?\'&l=\'+l:\'\';j.async=true;j.src=\n' +
                            '            \'https://www.googletagmanager.com/gtm.js?id=\'+i+dl;f.parentNode.insertBefore(j,f);\n' +
                            '        })(window,document,\'script\',\'dataLayer\',\'' + this.$gtmTag + '\');',
                    },
                    {
                        type: 'application/ld+json',
                        body: true,
                        innerHTML: '{\n' +
                            '   "@context": "http://schema.org",\n' +
                            '   "@type": "Organization",\n' +
                            '   "name": "' + this.$siteName + '",\n' +
                            '   "url": "https://' + this.$hostname + '/",\n' +
                            '   "logo": "' + this.$logo + '"\n' +
                            '}',
                    },
                    {
                        type: 'application/ld+json',
                        body: true,
                        innerHTML: '{\n' +
                            '    "@context": "https://schema.org",\n' +
                            '    "@type": "WebSite",\n' +
                            '    "url": "' + this.$siteUrl + '",\n' +
                            '    "potentialAction": {\n' +
                            '        "@type": "SearchAction",\n' +
                            '        "target": "' + this.$siteUrl + '{search_term_string}-jobs",\n' +
                            '        "query-input": "required name=search_term_string"\n' +
                            '    }\n' +
                            '}',
                    },
                    { type: 'text/javascript', src: 'https://www.googletagservices.com/tag/js/gpt.js', defer: true },
                ];
            },
        },
    };
