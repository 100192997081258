
    import { saveAs } from 'file-saver';

    import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

    import {
        faArrowToBottom as falArrowToBottom,
    } from '@fortawesome/pro-light-svg-icons/faArrowToBottom';

    export default {
        name: 'ResumeQualityFeedbackResume',

        components: {
            FontAwesomeIcon,
        },

        props: {
            resume: {
                type: Object,
                default: () => {
                },
            },
            resumePreDownload: {
                type: Object,
                default: () => {
                },
            },
        },

        data() {
            return {
                icons: {
                    falArrowToBottom,
                },
            };
        },

        methods: {
            downloadResume() {
                this.$store.dispatch('me/downloadResume', this.resume.id).then((response) => {
                    const url = window.URL.createObjectURL(new Blob([response]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', this.resume.file_name);
                    document.body.appendChild(link);
                    link.click();
                }).catch((error) => {
                    console.log(error);
                });
            },

            saveFile() {
                const blob = new Blob([this.resumePreDownload.content]);
                if (navigator.userAgent.match('CriOS')) {
                    const reader = new FileReader();
                    reader.onload = function() {
                        window.location.href = reader.result;
                    };
                    reader.readAsDataURL(blob);
                } else {
                    saveAs(blob, this.resume.file_name);
                }
            },
        },
    };
