export default {
    PROFILE_TASKS(state, data) {
        state.profileTasks = data;
    },

    DISMISS_PROFILE_TASK_STATUS(state, id) {
        state.profileTasks.forEach((o, idx) => {
            if (o.id === id) {
                state.profileTasks[idx].status = 'dismissed';
            }
        });
    },

    COMPLETE_PROFILE_TASK_STATUS(state, id) {
        state.profileTasks.forEach((o, idx) => {
            if (o.id === id) {
                state.profileTasks[idx].status = 'completed';
            }
        });
    },

    REVISE_PROFILE_TASK_STATUS(state, id) {
        state.profileTasks.forEach((o, idx) => {
            if (o.id === id) {
                state.profileTasks[idx].status = 'new';
            }
        });
    },

    PAUSE_PROFILE_TASK_STATUS(state, id) {
        state.profileTasks.forEach((o, idx) => {
            if (o.id === id) {
                state.profileTasks[idx].status = 'paused';
            }
        });
    },
};
