export default {
    getWorkExperiences(context, data) {
        const url = 'v1/me/work-experience';
        return this.$axios.$get(url, data);
    },

    storeWorkExperience(context, data) {
        const url = 'v1/me/work-experience';
        return this.$axios.$post(url, data);
    },

    updateWorkExperience(context, { workExperienceId, data }) {
        const url = `v1/me/work-experience/${workExperienceId}`;
        return this.$axios.$put(url, data);
    },

    destroyWorkExperience(context, workExperienceId) {
        const url = `v1/me/work-experience/${workExperienceId}`;
        return this.$axios.$delete(url);
    },
};
