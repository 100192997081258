
    import { mapMutations } from 'vuex';

    import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
    import {
        faCog as farCog,
    } from '@fortawesome/pro-regular-svg-icons/faCog';

    import {
        faXmark as farXmark,
    } from '@fortawesome/pro-regular-svg-icons/faXmark';

    export default {
        name: 'SidebarHeader',

        components: {
            FontAwesomeIcon,
        },

        data() {
            return {
                icons: {
                    farCog,
                    farXmark,
                },
            };
        },

        methods: {
            ...mapMutations('appInfo', {
                $_closeSidebar: 'CLOSE_SIDEBAR',
            }),

            closeSidebar() {
                this.$_closeSidebar();
            },

            goToSettings() {
                this.$router.push({
                    name: 'settings',
                });
            },
        },
    };
