export default function dataLayer(obj) {
    if (!obj || typeof window === 'undefined') {
        return;
    }
    if (obj.event) {
        if (!obj.site_position) {
            obj.site_position = undefined;
        }

        if (!obj.cta) {
            obj.cta = undefined;
        }

        if (!obj.code) {
            obj.code = undefined;
        }

        if (!obj.seeker_data?.id) {
            obj.seeker_data = undefined;
            obj.seeker_match_score = undefined;
        }

        if (!obj.job_id) {
            emptyJobVariables(obj);
        }

        if (obj.event !== 'job_search' && obj.event !== 'job_search_click' && obj.site_position !== 'SEARCH_RESULT') {
            emptySearchVariables(obj);
        }
    }
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push(obj);
}

export function emptyJobVariables(obj) {
    obj.job_id = undefined;
    obj.job_uuid = undefined;
    obj.job_category = undefined;
    obj.job_category_id = undefined;
    obj.job_occupation = undefined;
    obj.job_occupation_id = undefined;
    obj.job_occupation_list = undefined;
    obj.job_title = undefined;
    obj.job_njt_id = undefined;
    obj.job_njt = undefined;
    obj.job_type_id = undefined;
    obj.job_type = undefined;
    obj.contract_type_id = undefined;
    obj.contract_type = undefined;
    obj.job_mode_id = undefined;
    obj.job_mode = undefined;
    obj.company_size_id = undefined;
    obj.company_size = undefined;
    obj.sector = undefined;
    obj.job_sector = undefined;
    obj.job_skills = undefined;
    obj.job_certifications = undefined;
    obj.industry = undefined;
    obj.job_industry = undefined;
    obj.job_source_code = undefined;
    obj.job_channel_id = undefined;
    obj.job_account_id = undefined;
    obj.job_account_name = undefined;
    obj.job_brand_id = undefined;
    obj.job_brand_name = undefined;
    obj.job_bucket_code = undefined;
    obj.job_campaign_id = undefined;
    obj.job_partner_id = undefined;
    obj.job_apply_type_id = undefined;
    obj.job_activates_at = undefined;
    obj.job_expires_at = undefined;
    obj.job_flow_enabled = undefined;
    obj.job_location = undefined;
    obj.job_location_id = undefined;
    obj.job_location_type = undefined;
    obj.j_loc_postcode = undefined;
    obj.j_loc_suburb_id = undefined;
    obj.j_loc_area_id = undefined;
    obj.j_loc_region_id = undefined;
    obj.j_loc_state_id = undefined;
    obj.j_loc_country_id = undefined;
    obj.j_loc_country_name = undefined;
    obj.j_loc_country_code = undefined;
    obj.j_loc_state_code = undefined;
    obj.j_loc_suburb_name = undefined;
    obj.j_loc_area_name = undefined;
    obj.j_loc_region_name = undefined;
    obj.j_loc_state_name = undefined;
    obj.job_pay_hidden = undefined;
    obj.job_pay_max = undefined;
    obj.job_pay_min = undefined;
    obj.job_pay_type = undefined;
    obj.job_pay_estimated_min = undefined;
    obj.job_pay_estimated_max = undefined;
    obj.job_product_id = undefined;
    obj.job_reference = undefined;
    obj.job_refreshed_at = undefined;
    obj.job_sponsored = undefined;
    obj.job_is_onsite_apply = undefined;
    obj.apply_questions_count = undefined;
    obj.apply_questions_step_count = undefined;
    obj.job_position = undefined;
    obj.row_position = undefined;
}

export function emptySearchVariables(obj) {
    obj.search_keyword = undefined;
    obj.search_njt_id = undefined;
    obj.search_njt = undefined;
    obj.search_location = undefined;
    obj.search_location_id = undefined;
    obj.s_loc_type = undefined;
    obj.s_loc_normalised = undefined;
    obj.s_loc_suburb_id = undefined;
    obj.s_loc_suburb_name = undefined;
    obj.s_loc_area_id = undefined;
    obj.s_loc_area_name = undefined;
    obj.s_loc_region_id = undefined;
    obj.s_loc_region_name = undefined;
    obj.s_loc_state_id = undefined;
    obj.s_loc_state_name = undefined;
    obj.s_loc_country_id = undefined;
    obj.s_loc_country_name = undefined;
    obj.search_occupation = undefined;
    obj.search_occupation_id = undefined;
    obj.search_category = undefined;
    obj.search_category_id = undefined;
    obj.search_job_type = undefined;
    obj.search_job_type_id = undefined;
    obj.search_results_count = undefined;
    obj.search_sort_by = undefined;
    obj.pagination_page = undefined;
}

export function getDataLayer() {
    if (typeof window === 'undefined') {
        return;
    }
    return window.dataLayer;
}

export function findInDataLayer({ ...payload }) {
    const key = payload.key;
    const value = payload.value;
    const isContainValue = payload.isContainValue || false;

    return window.dataLayer.find((obj, index) => {
        if (isContainValue) {
            return obj[key] ? obj[key].includes(value) : false;
        }

        return obj[key] === value;
    });
}
