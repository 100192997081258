export default {
    STORE_EDUCATION(state, data) {
        state.education = data;
    },

    PUT_EDUCATION(state, data) {
        const index = state.education.findIndex(o => o.id === data.id);

        if (index >= 0) {
            state.education.splice(index, 1, data);
        } else {
            state.education.unshift(data);
        }
    },

    DESTROY_EDUCATION(state, id) {
        const index = state.education.findIndex(o => o.id === id);

        if (index >= 0) {
            state.education.splice(index, 1);
        }
    },
};
