import me from '@/store/me/actions/me';
import savedJob from '@/store/me/actions/savedJob';
import savedSearch from '@/store/me/actions/savedSearch';
import appliedJob from '@/store/me/actions/appliedJob';
import jobEvents from '@/store/me/actions/jobEvents';
import discovery from '@/store/me/actions/discovery';
import attribute from '@/store/me/actions/attribute';
import coverLetter from '@/store/me/actions/coverLetter';
import resume from '@/store/me/actions/resume';
import partner from '@/store/me/actions/partner';
import subscriptions from '@/store/me/actions/subscriptions';
import scoring from '@/store/me/actions/scoring';
import onesignal from '@/store/me/actions/onesignal';
import workLocation from '@/store/me/actions/workLocation';
import desiredOccupation from '@/store/me/actions/desiredOccupation';
import workExperience from '@/store/me/actions/workExperience';
import education from '@/store/me/actions/education';
import profileTask from '@/store/me/actions/profileTask';

export default {
    ...me,
    ...savedJob,
    ...savedSearch,
    ...appliedJob,
    ...jobEvents,
    ...discovery,
    ...attribute,
    ...coverLetter,
    ...resume,
    ...partner,
    ...subscriptions,
    ...scoring,
    ...onesignal,
    ...workLocation,
    ...desiredOccupation,
    ...workExperience,
    ...education,
    ...profileTask,

    storeAllUserData(context, data) {
        const user = data;

        if (user.attributeOptions) {
            context.commit('STORE_ATTRIBUTE_OPTION', user.attributeOptions);
            delete user.attributeOptions;
        }

        if (user.education) {
            context.commit('STORE_EDUCATION', user.education);
            delete user.education;
        }

        if (user.workExperience) {
            context.commit('STORE_WORK_EXPERIENCE', user.workExperience);
            delete user.workExperience;
        }

        if (user.workLocations) {
            context.commit('STORE_WORK_LOCATION', user.workLocations);
            delete user.workLocations;
        }

        if (user.desiredOccupations) {
            context.commit('STORE_DESIRED_OCCUPATION', user.desiredOccupations);
            delete user.desiredOccupations;
        }

        if (user.resumes) {
            context.commit('STORE_RESUME', user.resumes);
            delete user.resumes;
        }

        if (user.widgets) {
            context.commit('STORE_WIDGET', user.widgets);
            delete user.widgets;
        }

        context.commit('STORE_USER', user);
    },

    checkApplication(context, jobUuid) {
        const url = `v1/me/check-application/${jobUuid}`;
        return this.$axios.$get(url);
    },

    pushToOnboarding({ state }, el) {
        if (typeof window === 'undefined') {
            return;
        }
        window.location.href = `${this.$siteUrl}update-profile`;
    },
};
