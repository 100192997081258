
    export default {
        name: 'C1CircularProgressBar',

        props: {
            circleSize: {
                type: Number,
                required: true,
                default: 110,
            },

            contentSize: {
                type: String,
                default: 'sm',
            },

            pathWidth: {
                type: Number,
                required: false,
                default: null,
            },

            strokeWidth: {
                type: Number,
                required: true,
                default: 10,
            },

            score: {
                type: Number,
                required: false,
                default: 0,
            },

            scoreSuffix: {
                type: String,
                required: false,
                default: '%',
            },

            title: {
                type: String,
                required: false,
                default: 'match',
            },

            hideContent: {
                type: Boolean,
                required: false,
                default: false,
            },

            hidePath: {
                type: Boolean,
                default: false,
            },

            glow: {
                type: Boolean,
                default: false,
            },
        },

        data() {
            return {
                colors: {
                    base: 'var(--light500)',
                    low: '#FF6B5D',
                    medium: '#F5BB23',
                    high: '#72B84C',
                },
                scoreNumber: 0,
            };
        },

        computed: {
            contentClass() {
                let className;
                if (this.contentSize === 'md') {
                    className = 'c1-circular-progress-bar__content-md';
                } else if (this.contentSize === 'lg') {
                    className = 'c1-circular-progress-bar__content-lg';
                } else if (this.contentSize === 'xl') {
                    className = 'c1-circular-progress-bar__content-xl';
                } else {
                    className = 'c1-circular-progress-bar__content-sm';
                }
                return className;
            },

            getViewBox() {
                return `0 0 ${this.circleSize} ${this.circleSize}`;
            },

            getRadius() {
                return (this.circleSize / 2) - (this.strokeWidth / 2);
            },

            getCircumference() {
                return 2 * 3.14 * this.getRadius;
            },

            getCircumferenceOffSet() {
                return this.getCircumference * (1 - this.score / 100);
            },

            strokeColor() {
                let colorValue;
                if (this.score <= 33) {
                    colorValue = this.colors.low;
                } else if (this.score > 66) {
                    colorValue = this.colors.high;
                } else {
                    colorValue = this.colors.medium;
                }
                return colorValue;
            },

            colorByScore() {
                let className;
                if (this.score <= 33) {
                    className = 'c1-circular-progress-bar--low';
                } else if (this.score > 66) {
                    className = 'c1-circular-progress-bar--high';
                } else {
                    className = 'c1-circular-progress-bar--medium';
                }
                return className;
            },

            glowStyle() {
                return this.glow
                    ? {
                        fillOpacity: 0,
                        fill: this.strokeColor,
                        filter: 'url("#glow")',
                    }
                    : '';
            },
        },

        created() {
            this.scoreNumber = this.score;

            if (this.score > 100) {
                this.scoreNumber = 100;
            }

            if (this.score < 0) {
                this.scoreNumber = 0;
            }
        },
    };
