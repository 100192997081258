import has from 'lodash/has';
import numeral from 'numeral';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import JobPay from '@/utils/JobPay.class';
import { slugify } from '@/utils/helpers';

dayjs.extend(utc);

export default {
    jobTitle(state) {
        return state.job.job_title || '';
    },

    companyName(state) {
        return state.job.company_name || '';
    },

    primaryCompanyName(state) {
        return state.job.primary_brand_name || '';
    },

    displayPrimaryBrandCrumb(state) {
        return state.job.display_primary_brand_crumb || false;
    },

    companyPrimaryColor(state) {
        return has(state.job, 'brand_details.primary_color') ? state.job.brand_details.primary_color : '';
    },

    companyLogoUrl(state) {
        return state.job.company_logo_url || '';
    },

    companySquareLogoUrl(state) {
        return state.job.company_square_logo_url || '';
    },

    companySize(state) {
        return state.job.company_size_label || '';
    },

    companyLink(state) {
        if (state.job.brand_slug) {
            return `/jobs/br_${state.job.brand_slug}`;
        }

        if (state.job.company_name) {
            return `/jobs/br_${slugify(state.job.company_name)}`;
        }

        return '';
    },

    primaryCompanyLink(state) {
        if (state.job.primary_brand_slug) {
            return `/jobs/br_${state.job.primary_brand_slug}`;
        }
        return '';
    },

    companyMedias(state) {
        return has(state.job, 'brand_details.medias') ? state.job.brand_details.medias : [];
    },

    postedAt(state) {
        const date = state.job.activates_at;
        return state.is_apply_enabled ? dayjs.utc(date).local().format('D MMM YYYY') : '';
    },

    postedAtLabel(state) {
        const dateLabel = (state.job.is_expired || state.show_original_posted_date) ? 'Originally posted on' : 'Date posted';
        return dateLabel;
    },

    expiresAt(state) {
        return (state.job.is_expired && state.is_apply_enabled ? dayjs.utc(state.job.expires_at).local().format('D MMM YYYY') : '');
    },

    expiringAt(state) {
        return state.job.expires_at ? dayjs.utc(state.job.expires_at).local().format('D MMM YYYY') : '';
    },

    showPay(state, getters) {
        return !!getters.payType;
    },

    pay(state, getters) {
        return new JobPay(
            state.job.pay_min,
            state.job.pay_max,
            getters.payType,
            false,
            getters.isPayEstimated,
            state.job.job_type_label,
            state.job.contract_type_label,
            state.job.pay_hidden,
            state.job.pay_is_above_avg,
        );
    },

    marketPay(state, getters) {
        const payMin = has(state.job, 'pay_chart_data.min_market_pay') ? state.job.pay_chart_data.min_market_pay : 0;
        const payMax = has(state.job, 'pay_chart_data.max_market_pay') ? state.job.pay_chart_data.max_market_pay : 0;

        return new JobPay(
            payMin,
            payMax,
            'annual_salary',
            getters.payType !== 'annual_salary',
            getters.isPayEstimated,
            state.job_type_label,
            state.contract_type_label,
        );
    },

    marketAveragePayShortLabel(state, getters) {
        if (!getters.marketAveragePay) {
            return '';
        }

        let output = getters.marketAveragePay;

        output = `${numeral(output).format('$0a')}`;

        if (getters.isPayTypeHourly) {
            output += '/hr';
        }

        return output;
    },

    marketAveragePay(state, getters) {
        const output = has(state.job, 'pay_chart_data.average_market_pay') ? Math.round(parseFloat(state.job.pay_chart_data.average_market_pay)) : 0;

        if (getters.isPayTypeHourly) {
           return Math.round(output / 1976);
        }
        return output;
    },

    chartData(state) {
        return has(state.job, 'pay_chart_data.chart_data') && state.job.pay_chart_data.chart_data.length ? state.job.pay_chart_data.chart_data : '';
    },

    payData(state) {
        return has(state.job, 'pay_chart_data') ? state.job.pay_chart_data : '';
    },

    chartType(state) {
        return has(state.job, 'pay_chart_data.chart_type') ? state.job.pay_chart_data.chart_type : 'histogram';
    },

    isChartTypeRange(state, getters) {
        return getters.chartType === 'range';
    },

    isChartTypeHistogram(state, getters) {
        return getters.chartType === 'histogram';
    },

    showChart(state) {
        return has(state.job, 'pay_chart_data');
    },

    payDescription(state) {
        return state.job.pay_description || '';
    },

    payLabel(state, getters) {
        let output = 'Base pay';

        if (getters.isPayEstimated || getters.isPayHidden) {
            output = 'Estimated';
        } else if (getters.isPayAboveAvg) {
            output = 'Above avg.';
        }

        return output;
    },

    payType(state) {
        return state.job.pay_type || '';
    },

    isPayTypeAnnual(state, getters) {
        return getters.payType === 'annual_salary';
    },

    isPayTypeHourly(state, getters) {
        return getters.payType === 'hourly_rate';
    },

    isPayDaily(state, getters) {
        return getters.payType === 'per_day';
    },

    isPayMonthly(state, getters) {
        return getters.payType === 'per_month';
    },

    isPayEstimated(state) {
        return !!state.job.pay_is_estimated;
    },

    isPayAboveAvg(state) {
        return !!state.job.pay_is_above_avg;
    },

    isPrivateListing(state) {
        return state.job.brand_is_hidden;
    },

    isPayHidden(state) {
        return state.job.pay_hidden;
    },

    categoryLabel(state) {
        return state.job.category_label || '';
    },

    occupationLabel(state) {
        return state.job.occupation_label || '';
    },

    contractTypeLabel(state) {
        return state.job.contract_type_label || '';
    },

    jobTypeLabel(state) {
        return state.job.job_type_label || '';
    },

    jobModeLabel(state) {
        return state.job.job_mode_label || '';
    },

    careerLevelLabel(state) {
        return state.job.career_level_label && state.job.career_level_label.length ? state.job.career_level_label.join(', ') : '';
    },

    industryLabel(state) {
        return state.job.industry_label && state.job.industry_label.length ? state.job.industry_label.join(', ') : '';
    },

    sectorLabel(state) {
        return state.job.sector_label && state.job.sector_label.length ? state.job.sector_label.join(', ') : '';
    },

    educationLevelLabel(state) {
        return state.job.education_level_label && state.job.education_level_label.length ? state.job.education_level_label.join(', ') : '';
    },

    workAuthorisationLabel(state) {
        return state.job.work_authorisations_label && state.job.work_authorisations_label.length ? state.job.work_authorisations_label.join(', ') : '';
    },

    languagesLabel(state) {
        return state.job.languages_label && state.job.languages_label.length ? state.job.languages_label.join(', ') : '';
    },

    locationLabel(state) {
        if (state.job.location_type === 'STATE') {
            return state.job.location_level_4_label || state.job.location_label;
        } else {
            return state.job.location_label || '';
        }
    },

    jobBody(state) {
        return state.job.body || '';
    },

    skillsDetails(state) {
        return state.job.skills_details || [];
    },

    certificationsDetails(state) {
        return state.job.certifications_details || [];
    },

    perksDetails(state) {
        return state.job.perks_details || [];
    },

    locationLink(state) {
        return `/jobs/in-${state.job.location_slug}`;
    },

    isApplyDisabled(state) {
        return state.job.apply_type_id === 5;
    },

    hasApplied(state) {
        return !!state.job.has_applied;
    },
};
