export default {
    STORE_RESUME(state, data) {
        state.resumes = data;
    },

    PUT_RESUME(state, data) {
        const index = state.resumes.findIndex(o => o.id === data.id);

        if (index >= 0) {
            state.resumes.splice(index, 1, data);
        } else {
            state.resumes.unshift(data);
        }
    },

    DESTROY_RESUME(state, id) {
        const index = state.resumes.findIndex(o => o.id === id);

        if (index >= 0) {
            state.resumes.splice(index, 1);
        }
    },

    UPDATE_RESUME_DEFAULT_FIELD(state, id) {
        state.resumes.forEach((o, idx) => {
            state.resumes[idx].is_default = false;
            if (o.id === id) {
                state.resumes[idx].is_default = true;
            }
        });
    },

    SET_RECENT_RESUME_REVIEW(state, data) {
        const index = state.resumes.findIndex(o => o.id === data.resume_id);
        if (index >= 0) {
            this._vm.$set(state.resumes[index], 'has_recent_resume_review', true);
        }
    },

};
