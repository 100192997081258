import Cookie from 'js-cookie';

export default {
    getDiscovery(context, page) {
        const domainSessionId = Cookie.get(process.env.COOKIE_DOMAIN_SESSION);
        const guestEmail = Cookie.get('guest_email');
        let url = `v1/me/discovery/${page}?domain_session_id=${domainSessionId}`;
        if (guestEmail) {
            url = `${url}&guest_email=${guestEmail}`;
        }
        return this.$axios.$get(url);
    },

    getDiscoveryListJob(context, data) {
        let url = 'v1/me/list-job';
        const query = Object.keys(data).map(key => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`).join('&');
        if (query) {
            url += `?${query}`;
        }
        return this.$axios.$get(url);
    },

    toggleJobVisibility({ dispatch }, jobUuid) {
        const url = `v1/me/toggle-job-visibility/${jobUuid}`;
        return this.$axios.$post(url);
    },

    getDiscoveryNavCount({ commit }, data) {
        const url = 'v1/me/discovery-nav-count';
        return this.$axios.$get(url).then((response) => {
            commit('STORE_DISCOVERY_NAV', response);
            return response;
        });
    },

    updateFrequencyDiscovery(context, { data }) {
        const url = 'v1/me/discovery-alerts';
        return this.$axios.$post(url, data);
    },

    getDiscoveryAlerts(context, page) {
        const url = 'v1/me/discovery-alerts';
        return this.$axios.$get(url);
    },
};
