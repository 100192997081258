
    import { mapState, mapActions } from 'vuex';
    import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
    import {
        faChevronDown as farChevronDown,
    } from '@fortawesome/pro-regular-svg-icons/faChevronDown';

    import {
        faChevronUp as farChevronUp,
    } from '@fortawesome/pro-regular-svg-icons/faChevronUp';

    import {
        faQuestionCircle as farQuestionCircle,
    } from '@fortawesome/pro-regular-svg-icons/faQuestionCircle';

    import {
        faTimes as falTimes,
    } from '@fortawesome/pro-light-svg-icons/faTimes';

    import ResumeQualityFeedbackIssues from '@/components/Seeker/ResumeQualityFeedback/ResumeQualityFeedbackIssues';
    import ResumeQualityFeedbackResume from '@/components/Seeker/ResumeQualityFeedback/ResumeQualityFeedbackResume';
    import ResumeQualityFeedbackCta from '@/components/Seeker/ResumeQualityFeedback/ResumeQualityFeedbackCta';
    import ResumeQualityFeedback100 from '@/components/Seeker/ResumeQualityFeedback/ResumeQualityFeedback100';

    export default {
        name: 'ResumeQualityFeedbackModalMobile',

        components: {
            FontAwesomeIcon,
            ResumeQualityFeedbackIssues,
            ResumeQualityFeedbackResume,
            ResumeQualityFeedbackCta,
            ResumeQualityFeedback100,
        },

        props: {
            resume: {
                type: Object,
                default: () => {
                },
            },
        },

        data() {
            return {
                isOpen: false,
                showDescription: false,
                resumePreDownload: {
                    content: null,
                    mime_type: null,
                },
                icons: {
                    farChevronDown,
                    farChevronUp,
                    farQuestionCircle,
                    falTimes,
                },
            };
        },

        computed: {
            ...mapState({
                $_global: state => state.global,
                $_meResumes: state => state.me.resumes,
            }),

            currentResume() {
                if (this.resume) {
                    return this.resume;
                } else {
                    return this.$_meResumes.slice(-1)[0];
                }
            },
        },

        created() {
            this.openModal();

            const unregisterRouterGuard = this.$router.beforeEach((to, from, next) => {
                this.back();

                next(false);
            });

            this.$once('hook:destroyed', () => {
                unregisterRouterGuard();
            });

            if (process.browser) {
                this.preFetchResume();
            }
        },

        beforeDestroy() {
            this.closeModal();
        },

        methods: {
            ...mapActions('me', {
                $_downloadResume: 'downloadResume',
            }),

            back() {
                this.closeModal();
            },

            toggleDescription() {
                this.showDescription = !this.showDescription;
            },

            close() {
                this.$emit('close');
            },

            openModal() {
                this.isOpen = true;
            },

            closeModal() {
                this.isOpen = false;
            },

            preFetchResume() {
                this.$_downloadResume(this.resume.id).then((response) => {
                    this.resumePreDownload.content = response.data;
                    this.resumePreDownload.mime_type = response.headers['content-type'];
                }).catch((error) => {
                    console.log(error);
                });
            },
        },
    };
