export default {
    getAppInfo({ commit }) {
        const url = 'v1/common/app-info';
        return this.$axios.$get(url).then((response) => {
            commit('STORE', response.data);
            return response;
        });
    },

    checkDevice(context) { // TODO deprecated, do not use
        return new Promise((resolve) => {
            context.commit('STORE_DEVICE_SIZE', window);
            resolve();
        });
    },
};
