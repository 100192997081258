const middleware = {}

middleware['AppInfo'] = require('../middleware/AppInfo.js')
middleware['AppInfo'] = middleware['AppInfo'].default || middleware['AppInfo']

middleware['Authenticated'] = require('../middleware/Authenticated.js')
middleware['Authenticated'] = middleware['Authenticated'].default || middleware['Authenticated']

middleware['DataLayer'] = require('../middleware/DataLayer.js')
middleware['DataLayer'] = middleware['DataLayer'].default || middleware['DataLayer']

middleware['maintenance'] = require('../middleware/maintenance.js')
middleware['maintenance'] = middleware['maintenance'].default || middleware['maintenance']

middleware['OneAuth'] = require('../middleware/OneAuth.js')
middleware['OneAuth'] = middleware['OneAuth'].default || middleware['OneAuth']

middleware['Onesignal'] = require('../middleware/Onesignal.js')
middleware['Onesignal'] = middleware['Onesignal'].default || middleware['Onesignal']

middleware['Redirect'] = require('../middleware/Redirect.js')
middleware['Redirect'] = middleware['Redirect'].default || middleware['Redirect']

middleware['RedirectIfLoggedIn'] = require('../middleware/RedirectIfLoggedIn.js')
middleware['RedirectIfLoggedIn'] = middleware['RedirectIfLoggedIn'].default || middleware['RedirectIfLoggedIn']

middleware['resetStoreDataOnNavigation'] = require('../middleware/resetStoreDataOnNavigation.js')
middleware['resetStoreDataOnNavigation'] = middleware['resetStoreDataOnNavigation'].default || middleware['resetStoreDataOnNavigation']

export default middleware
