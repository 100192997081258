export default {
    getBrand(context, brandId) {
        const url = `v1/brands/${brandId}`;
        return this.$axios.$get(url);
    },

    getBrandBySlug(context, brandSlug) {
        const url = `v1/brands/slug/${brandSlug}`;
        return this.$axios.$get(url);
    },

    getBrandArticles(context, brandId) {
        const url = `v1/brands/${brandId}/articles`;
        return this.$axios.$get(url);
    },

    getBrandArticle(context, { brandId, articleSlug }) {
        const url = `v1/brands/${brandId}/articles/${encodeURI(articleSlug)}`;
        return this.$axios.$get(url);
    },

    getBrands(context, brandName) {
        const url = `v1/brands/suggest?brand_name=${brandName}`;
        return this.$axios.$get(url);
    },

    async getBrandEeoTags(context, brandId = null) {
        let data = null;
        const url = brandId ? `v1/brands/${brandId}/equal-opportunity-tags` : 'v1/brands/equal-opportunity-tags';

        if (process.server) {
            const cacheManager = require('cache-manager');
            const fsStore = require('cache-manager-fs-hash');
            const diskCache = cacheManager.caching({
                store: fsStore,
                options: {
                    path: 'diskcache', // path for cached files
                    ttl: 60 * 60, // time to life in seconds
                    subdirs: true, // create subdirectories to reduce the
                    // files in a single dir (default: false)
                },
            });
            data = await diskCache.get(url);
            if (data) { // get data from cache if exists
                return data;
            } else { // else get data from API
                return this.$axios.$get(url)
                    .then(async(response) => {
                        await diskCache.set(url, response);
                        return response;
                    });
            }
        } else { // else get data from API
            return this.$axios.$get(url);
        }
    },
};
