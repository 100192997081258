export default {
    getWorkLocations() {
        const url = 'v1/me/work-locations';
        return this.$axios.$get(url);
    },

    attachWorkLocation(context, locationId) {
        const url = `v1/me/work-locations/${locationId}`;
        return this.$axios.$put(url);
    },

    detachWorkLocation(context, locationId) {
        const url = `v1/me/work-locations/${locationId}`;
        return this.$axios.$delete(url);
    },
};
