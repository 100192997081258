
    import { mapState } from 'vuex';
    import SeekerAvatarProgressbar from '@/components/Seeker/SeekerAvatarProgressbar';

    export default {
        name: 'SidebarStrengthMini',

        components: {
            SeekerAvatarProgressbar,
        },

        data() {
            return {
                animate: false,
            };
        },

        computed: {
            ...mapState({
                $_profileScore: state => state.me.user.profile_completion,
            }),

            getProfileScore() {
                return this.$_profileScore;
            },

            colorByScore() {
                let className;
                if (this.getProfileScore <= 33) {
                    className = 'is-low';
                } else if (this.getProfileScore > 66) {
                    className = 'is-high';
                } else {
                    className = 'is-medium';
                }
                return className;
            },
        },

        methods: {
            goToProfile() {
                this.$router.push({
                    name: 'profile',
                });
            },

            onMouseover() {
                if (['xl', 'inf'].includes(this.$mq)) {
                    this.animate = true;
                }
            },

            onMouseleave() {
                this.animate = false;
            },
        },
    };
