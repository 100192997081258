
    export default {
        name: 'AnimateRing',

        props: {
            animate: {
                type: Boolean,
                default: true,
            },
        },

        watch: {
            animate(isAnimate) {
                if (isAnimate) {
                    this.animation();
                } else {
                    this.stopAnimation();
                }
            },
        },

        mounted() {
            if (this.animate) {
                this.animation();
            }
        },

        methods: {
            animation() {
                this.$velocity(this.$refs.ray, { rotateZ: '+=360' }, { duration: 29900, easing: 'linear', loop: true });
            },

            stopAnimation() {
                this.$velocity(this.$refs.ray, 'stop', true);
            },
        },
    };
