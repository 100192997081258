
    export default {
        name: 'C1Spinner',

        props: {
            color: {
                type: String,
                default: '',
            },
        },
    };
