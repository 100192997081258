
    import { mapState, mapGetters, mapMutations } from 'vuex';
    import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
    import {
        faBars as farBars,
    } from '@fortawesome/pro-regular-svg-icons/faBars';

    export default {
        name: 'SidebarBurger',

        components: {
            FontAwesomeIcon,
        },

        data() {
            return {
                icons: {
                    farBars,
                },
            };
        },

        computed: {
            ...mapState({
                $_appInfo: state => state.appInfo,
            }),

            ...mapGetters('appInfo', {
                $_isAppWarning: 'isAppWarning',
            }),

            classList() {
                return [
                    'aside-burger',
                    this.getWarningClass,
                ];
            },

            getWarningClass() {
                return this.$_isAppWarning ? 'is-warning' : '';
            },
        },

        methods: {
            ...mapMutations('appInfo', {
                $_openSidebar: 'OPEN_SIDEBAR',
            }),

            openSidebar() {
                this.$_openSidebar();
            },
        },
    };
