
    import { mapState, mapActions } from 'vuex';
    import Cookie from 'js-cookie';

    export default {
        name: 'AppHeader',

        data() {
            return {
                shouldMount: false,
            };
        },

        computed: {
            ...mapState({
                $_me: state => state.me.user,
            }),
        },

        mounted() {
            const cookieName = process.env.COOKIE_DOMAIN_SESSION;
            const currentDomainSession = Cookie.get(cookieName);
            if (!currentDomainSession || this.$route.name === 'signout') {
                window.addEventListener('message', (event) => {
                    const response = event.data;
                    if (response.domain_session_id) {
                        const cookieName = process.env.COOKIE_DOMAIN_SESSION;
                        Cookie.set(cookieName, response.domain_session_id, { expires: 365 * 10 });
                        this.$store.commit('eventTracking/SET', { key: 'isDomainSessionAvailable', value: true });
                    }

                    if (response.guest_email) {
                        Cookie.set('guest_email', response.guest_email, process.env.COOKIE_EXPIRY_TIME);
                    }

                    if (response.one_access_token && !this.$_me.id) {
                        Cookie.set(process.env.COOKIE_TOKEN_NAME, response.one_access_token, process.env.COOKIE_EXPIRY_TIME);
                        this.getMe();
                    }
                });
                this.shouldMount = true;
            } else {
                this.$store.commit('eventTracking/SET', { key: 'isDomainSessionAvailable', value: true });
            }
        },

        methods: {
            ...mapActions('me', {
                $_getMe: 'getMe',
                $_storeAllUserData: 'storeAllUserData',
                $_syncScoring: 'syncScoring',
                $_getJobEvents: 'getJobEvents',
            }),

            async getMe() {
                await this.$_getMe().then((response) => {
                    this.$_storeAllUserData(response.data);
                    if (this.$route.name === 'home') {
                        this.$router.push({ name: 'discover' });
                    }

                    this.$_syncScoring();
                    this.$_getJobEvents();
                });
            },

            getSharedSessionFrameSrc() {
                let frameSrc = 'https://www.careerone.com.au';
                if (process.env.APP_ENV === 'local') {
                    frameSrc = 'http://careerone-util.test';
                } else if (process.env.APP_ENV === 'staging') {
                    frameSrc = 'https://staging.careerone.xyz';
                }

                let finalFrameSrc = `${frameSrc}/media/shared-session?v=1.5`;

                if (this.$route.name === 'signout') {
                    finalFrameSrc = `${frameSrc}/media/shared-session?v=1.5&signout=1`;
                }
                return finalFrameSrc;
            },
        },
    };
