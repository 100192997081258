export const state = () => ({
    site: 'C1',
    app_version: '',
    server_id: '',
    country_code: '',
    isMobile: false, // TODO deprecated, do not use
    isMobileMenuOpen: false,
    isSidebarOpen: false,
    isNotificationBarOpen: false,
    sidebarLastClicked: '',
    appState: '',
    activeTile: '',
    isFirstDataLayerSaved: false,
});
