export default ({ store, route, app }) => {
    if (process.browser) {
        if (window.dataLayer) {
            window.dataLayer.length = 0;
            window.dataLayer.push(function() {
                this.reset();
            });
            const firstDataLayerFields = {
                app_version: store.state.appInfo.app_version,
                server_id: store.state.appInfo.server_id,
                user_ip_country_code: store.state.eventTracking.user_ip_country_code,
                platform: store.state.eventTracking.platform,
                app_id: store.state.eventTracking.app_id,
                site: app.$siteCode,
                platform_code: app.$platformCode,
            };

            if (store.state.me.user) {
                firstDataLayerFields.seeker_id = store.state.me.user.id;
                firstDataLayerFields.seeker_hash = store.state.me.user.email_hash;
            }

            window.dataLayer.push(firstDataLayerFields);
            window.dataLayer.push({
                event: 'pageview',
                page: {
                    path: route.fullPath,
                    full_path: `${app.$siteUrl}${route.fullPath.substring(1)}`,
                },
            });
        }
    }
};
