export default {
    getScoringData() {
        const url = 'v1/me/get-scoring-data';
        return this.$axios.$get(url);
    },

    getScoreMatrix() {
        const url = 'v1/common/score-matrix';
        return this.$axios.$get(url);
    },

    async syncScoring(context) {
        await Promise.all([
            context.dispatch('getScoreMatrix'),
            context.dispatch('getScoringData'),
        ]).then((response) => {
            context.commit('STORE_SCORING_MATRIX_DATA', response[0].data);
            context.commit('STORE_SCORING_DATA', response[1].data);
        });
    },
};
