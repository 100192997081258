export default {
    OPEN_APPLY_FORM(state) {
        state.user = {};
        state.is_apply_form_open = true;
        state.is_basic_questions_open = true;
        state.is_onboarding_questions_open = false;
        state.is_custom_questions_open = false;
        state.is_signin_modal_open = false;
        state.is_applied = false;
        // TODO: check a better way to set to false all fields
    },

    CLOSE_APPLY_FORM(state) {
        state.is_apply_form_open = false;
        state.is_apply_form_offsite_open = false;
        state.is_basic_questions_open = false;
        state.is_onboarding_questions_open = false;
        state.is_custom_questions_open = false;
        state.is_signin_modal_open = false;
    },

    OPEN_APPLY_OFFSITE_FORM(state) {
        state.is_apply_form_offsite_open = true;
    },

    CLOSE_APPLY_OFFSITE_FORM(state) {
        state.is_apply_form_offsite_open = false;
    },
};
