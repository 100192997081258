import { getTokenFromReq } from '@/utils/helpers';

export default async({ app, req, route, store }) => {
    store.commit('job/STORE_JOB', {});

    const routeNames = [
        'default-search-results',
        'search',
        'home',
        'test',
        'company-article',
        'company-search-results',
    ];

    if (!routeNames.includes(route.name)) {
        store.commit('search/SET_OBJECT', { key: 'searchPayload', value: {} });
    }

    if (process.server) {
        const token = getTokenFromReq(req);
        if (token) {
            await store.dispatch('me/getMe').then((response) => {
                store.commit('user/STORE_USER');
            }).catch((error) => {
                console.log(error);
                console.log(error.response.data);
            });
        }
    }
};
