export default {
    UPDATE_AUTH_OPTIONS(state, data) {
        state.authOptions = data;
    },

    RESET_AUTH_OPTIONS(state) {
        state.authOptions.canLoginLater = false;
        state.authOptions.hideForgotPassword = false;
        state.authOptions.registerWithPassword = true;
        state.authOptions.registerButtonLabel = 'Sign up with email';
        state.authOptions.loginButtonLabel = 'Login with email';
    },

    SET_AUTH_OPTION(state, data) {
        state.authOptions[data.key] = data.value;
    },

    SET_EMAIL_VALIDATING(state, data) {
        state.isEmailValidating = data;
    },

    SET_EMAIL_DEBOUNCE(state, data) {
        state.emailDebounce[data.key] = data.value;
    },

    RESET_EMAIL_DEBOUNCE(state) {
        state.emailDebounce = {
            is_validated: false,
            is_invalid: true,
            send_transactional: false,
            did_you_mean: '',
            is_forced: false,
        };
    },
};
