import { slugify } from '@/utils/helpers';

export default {
    currentJobIndex(state, getters, rootState) {
        return state.jobs.findIndex(o => o.uuid === rootState.job.job.uuid);
    },

    previousJob(state, getters) {
        const currentJobIndex = getters.currentJobIndex;
        if (currentJobIndex >= 0 && currentJobIndex > 0) {
            const job = state.jobs[currentJobIndex - 1];
            job.slug = slugify(job.job_title);

            return job;
        }

        return {};
    },

    nextJob(state, getters) {
        const currentJobIndex = getters.currentJobIndex;
        if (currentJobIndex >= 0 && currentJobIndex < state.jobs.length - 1) {
            const job = state.jobs[currentJobIndex + 1];
            job.slug = slugify(job.job_title);

            return job;
        }

        return {};
    },
};
