export const state = () => ({
    user: {},
    attributeOptions: [],
    education: [],
    workExperience: [],
    workLocations: [],
    desiredOccupations: [],
    savedJobs: [],
    savedSearches: [],
    appliedJobs: [],
    jobEvents: [],
    saveJobAfterLogin: '',
    saveAttributionOptionAfterLogin: '',
    isSearchSaveModalOpen: false,
    resumes: [],
    coverLetters: [],
    isResumeQualityFeedbackModalOpen: false,
    scoringData: {},
    scoringMatrixData: {},
    profileTasks: [],
    profileSections: [
        {
            menu_label: 'Profile strength',
            title: 'Profile strength',
            to: '#profile-strength',
            type: 'menu',
            position: 'top',
        },
        {
            menu_label: 'Basic info',
            title: 'Fill out your basic information',
            to: '#basic-info',
            section_code: 'basic-info',
            status: 'new',
            type: 'task',
            task_order: 3,
            position: 'middle',
        },
        {
            menu_label: 'Skills',
            title: 'Review your skills',
            to: '#skills',
            section_code: 'skills',
            status: 'new',
            type: 'task',
            position: 'middle',
            task_order: 4,
        },
        {
            menu_label: 'Work experience',
            title: 'Fill out your work experience',
            to: '#work-experiences',
            section_code: 'work-experiences',
            status: 'new',
            type: 'task',
            position: 'middle',
            task_order: 5,
        },
        {
            menu_label: 'Education',
            title: 'Fill out your education history',
            to: '#educations',
            section_code: 'educations',
            status: 'new',
            type: 'task',
            position: 'middle',
            task_order: 6,
        },
        {
            menu_label: 'License & certifications',
            title: 'Add licenses & certifications',
            to: '#licenses-and-certifications',
            section_code: 'licenses-and-certifications',
            status: 'new',
            type: 'task',
            position: 'middle',
            task_order: 7,
        },
        {
            menu_label: 'Resumes',
            title: 'Upload your latest resume',
            to: '#resumes',
            section_code: 'resumes',
            status: 'new',
            type: 'task',
            position: 'middle',
            task_order: 1,
        },
        {
            menu_label: 'Cover letters',
            title: 'Cover letters',
            to: '#cover-letters',
            section_code: 'cover-letters',
            status: 'new',
            position: 'middle',
            type: 'menu',
        },
        {
            menu_label: 'Work preferences',
            title: 'Confirm your work preferences',
            to: '#work-preferences',
            section_code: 'work-preferences',
            status: 'new',
            type: 'task',
            position: 'bottom',
            task_order: 1,
        },
        {
            menu_label: 'Diversity & inclusion',
            title: 'Diversity & inclusion',
            to: '#equal-opportunity',
            section_code: 'equal-opportunity',
            status: 'new',
            type: 'menu',
            position: 'bottom',
            task_order: 9,
        },
    ],
    discoveryNav: {
        init: true,
        discover: 0,
        saved_jobs: 0,
        saved_searches: 0,
    },
});
