export default {
    getSavedSearches(context, query) {
        let url = 'v1/me/saved-searches';
        if (query) {
            url += `?query=${query}`;
        }
        return this.$axios.$get(url);
    },

    storeSavedSearch(context, data) {
        const url = 'v1/me/saved-searches';
        return this.$axios.$post(url, data);
    },

    updateSavedSearch(context, { id, data }) {
        const url = `v1/me/saved-searches/${id}`;
        return this.$axios.$put(url, data);
    },

    destroySavedSearch(context, id) {
        const url = `v1/me/saved-searches/${id}`;
        return this.$axios.$delete(url);
    },

    updateFrequencySavedSearch(context, { id, data }) {
        const url = `v1/me/saved-searches/${id}/frequency`;
        return this.$axios.$put(url, data);
    },
};
