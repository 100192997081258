
    import { mapGetters } from 'vuex';
    import SidebarHeader from '@/components/Sidebar/SidebarHeader';
    import SidebarStrengthDetailed from '@/components/Sidebar/SidebarStrengthDetailed';
    import SidebarMenu from '@/components/Sidebar/SidebarMenu';
    import SidebarFooter from '@/components/Sidebar/SidebarFooter';

    export default {
        name: 'SidebarDefault',

        components: {
            SidebarHeader,
            SidebarStrengthDetailed,
            SidebarMenu,
            SidebarFooter,
        },

        computed: {
            ...mapGetters('me', {
                $_defaultResume: 'defaultResume',
            }),
        },
    };
