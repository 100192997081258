export default {
    getLeadgenPromotions(context, { keyword, location, occupationIds, categoryNames }) {
        const url = encodeURI(`v1/leadgen-promotions?keyword=${keyword}&location=${location}&occupation_ids=${occupationIds}&category_names=${categoryNames}`);
        return this.$axios.$get(url);
    },

    saveLeadgenPromotionImpression(context, { leadgenId }) {
        const url = `v1/leadgen-promotions/${leadgenId}/save-impression`;
        return this.$axios.$put(url);
    },

    acceptLeadgenPromotion(context, { leadgenId, data }) {
        const url = `v1/leadgen-promotions/${leadgenId}/accept`;
        return this.$axios.$put(url, data);
    },

    answerLeadgenPromotion(context, { leadgenId, questionId, data }) {
        const url = `v1/leadgen-promotions/${leadgenId}/questions/${questionId}`;
        return this.$axios.$post(url, data);
    },
};
