
    import { mapState, mapGetters, mapMutations } from 'vuex';
    import AppHeader from '@/components/Partials/AppHeader';
    import HtmlHeader from '@/components/Partials/HtmlHeader';

    export default {
        name: 'Search',

        components: {
            AppHeader,
        },

        mixins: [HtmlHeader],

        computed: {
            ...mapState({
                $_isSidebarOpen: state => state.appInfo.isSidebarOpen,
                $_me: state => state.me,
                $_global: state => state.global,
            }),

            ...mapGetters('me', {
                $_defaultResume: 'defaultResume',
            }),
        },

        methods: {
            ...mapMutations('appInfo', {
                $_closeSidebar: 'CLOSE_SIDEBAR',
            }),

            onWindowResize() {
                this.$store.dispatch('appInfo/checkDevice');
            },

            closeResumeModal() {
                this.$store.commit('me/CLOSE_RESUME_QUALITY_FEEDBACK_MODAL');
            },

            closeSidebar() {
                this.$_closeSidebar();
            },
        },

        head() {
            return this.head;
        },
    };
