
    export default {
        name: 'PoweredByC1',

        props: {
            variant: {
                type: String,
                default: 'dark',
            },
            sizeClass: {
                type: String,
                default: 'w-52',
            },
        },
    };
