export default {
    STORE_DISCOVERY_NAV(state, data) {
        state.discoveryNav = data;
        state.discoveryNav.init = false;
    },

    INCREASE_DISCOVERY_NAV_COUNT(state, key) {
        state.discoveryNav[key] = state.discoveryNav[key] + 1;
    },

    DECREASE_DISCOVERY_NAV_COUNT(state, key) {
        state.discoveryNav[key] = state.discoveryNav[key] - 1;
    },
};
