
    import AppHeader from '@/components/Partials/AppHeader';
    import HtmlHeader from '@/components/Partials/HtmlHeader';

    export default {
        name: 'Auth',

        components: {
            AppHeader,
        },

        mixins: [HtmlHeader],

        head() {
            return {
                link: [
                    { rel: 'stylesheet', href: `https://www.careerone.com.au/media/site-variables.css?domain=${this.$authOrigin}` },
                    { rel: 'stylesheet', href: this.$fontUrl },
                ],
            };
        },
    };
