export default {
    OPEN_CUSTOM_QUESTIONS(state) {
        state.is_apply_form_open = true;
        state.is_basic_questions_open = false;
        state.is_onboarding_questions_open = false;
        state.is_custom_questions_open = true;
        state.is_signin_modal_open = false;
    },

    CLOSE_CUSTOM_QUESTIONS(state) {
        state.is_custom_questions_open = false;
    },

    STORE_JOB_QUESTIONS(state, data) {
        state.questions = data;
    },

    STORE_CUSTOM_QUESTION_STEP(state, data) {
        state.custom_question_step = data;
    },

    STORE_CUSTOM_QUESTION_LOADING(state, data) {
        state.is_custom_question_loading = data;
    },
};
