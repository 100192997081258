export default {
    getMe(context) {
        const url = 'v1/me';
        return this.$axios.$get(url).then((response) => {
            context.dispatch('me/storeAllUserData', response.data, { root: true });
            return response;
        });
    },

    updateMyDetails({ dispatch }, data) {
        const url = 'v1/me';
        return this.$axios.$put(url, data).then((response) => {
            dispatch('storeAllUserData', response.data);
            return response;
        });
    },

    updateMyEmail(context, { email, password }) {
        const url = 'v1/me/email';
        return this.$axios.$put(url, { email, password });
    },

    uploadFile(context, data) {
        const url = 'v1/me/upload-files';
        return this.$axios.$post(url);
    },

    deleteFile(context, fileId) {
        const url = `v1/me/upload-files/${fileId}`;
        return this.$axios.$delete(url);
    },

    saveQuestionAnswers(context, { applyUuId, data }) {
        const url = `v1/me/application/${applyUuId}/answers`;
        return this.$axios.$post(url, data);
    },

    getSuggestions(context, data) {
        let url = 'v1/me/suggestions';
        const query = Object.keys(data).map(key => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`).join('&');
        if (query) {
            url += `?${query}`;
        }
        return this.$axios.$get(url);
    },

    deleteAccount() {
        const url = 'v1/me/delete-account';
        return this.$axios.$post(url);
    },

    deleteAvatar(context, id) {
        const url = `v1/me/avatar/${id}`;
        return this.$axios.$delete(url);
    },

    updateEqualOpportunityTags(context, eeoIds = []) {
        const url = 'v1/me/equal-opportunity-tags';
        const payload = {
            equal_opportunity_tag_ids: eeoIds,
        };
        return this.$axios.$put(url, payload);
    },
};
