export default {
    SAVED_SEARCH_STORE(state, data) {
        state.savedSearches = data;
    },

    DESTROY_SAVED_SEARCH(state, id) {
        const index = state.savedSearches.findIndex(o => o.id === id);
        if (index >= 0) {
            state.savedSearches.splice(index, 1);
        }
    },

    OPEN_SEARCH_SAVE_MODAL(state) {
        state.isSearchSaveModalOpen = true;
    },

    CLOSE_SEARCH_SAVE_MODAL(state) {
        state.isSearchSaveModalOpen = false;
    },
};
