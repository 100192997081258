import has from 'lodash/has';

import saveSearch from '@/store/search/mutations/saveSearch';

export default {
    ...saveSearch,

    SET(state, data) {
        state[data.key] = data.value;
    },

    SET_OBJECT(state, data) {
        state[data.key] = JSON.parse(JSON.stringify(data.value));
        if (data.key === 'searchPayload') {
            if (has(state.searchPayload, 'search_filters.posted_within_days') && state.searchPayload.search_filters.posted_within_days === '') {
                state.searchPayload.search_filters.posted_within_days = state.postedWithIn[0];
            }
            if (has(state.searchPayload, 'search_filters.brands') && state.searchPayload.search_filters.brands && state.searchPayload.search_filters.brands.length) {
                state.searchLayoutTemplate = 'companyPage';
            } else {
                state.searchLayoutTemplate = 'default';
            }
        }
    },

    SET_SEARCH_PAYLOAD(state, data) {
        state.searchPayload[data.key] = JSON.parse(JSON.stringify(data.value));
        if (has(state.searchPayload, 'search_filters.posted_within_days') && state.searchPayload.search_filters.posted_within_days === '') {
            state.searchPayload.search_filters.posted_within_days = state.postedWithIn[0];
        }
    },

    SET_SEARCH_FILTERS(state, data) {
        if (data.key !== 'page' && data.key !== 'sort_by') {
            state.searchPayload.search_filters.page = 1;
        }
        state.searchPayload.search_filters[data.key] = JSON.parse(JSON.stringify(data.value));
        if (data.key === 'posted_within_days') {
            if (data.value === '') {
                state.searchPayload.search_filters[data.key] = state.postedWithIn[0];
            }
        }
        if (!data.preventSearch) {
            state.emitSearchFilterChanged += 1;
        }
    },

    STORE_SEARCH_FILTER_ATTRIBUTE(state, { key, attributeOption }) {
        const index = state.searchPayload.search_filters[key].findIndex(o => o.id === attributeOption.id);
        if (index < 0) {
            state.searchPayload.search_filters.page = 1;
            state.searchPayload.search_filters[key].push(attributeOption);
            state.emitSearchFilterChanged += 1;
        }
    },

    DELETE_SEARCH_FILTER_ATTRIBUTE(state, { key, attributeOption, preventSearch }) {
        const index = state.searchPayload.search_filters[key].findIndex(o => o.id === attributeOption.id);
        if (index >= 0) {
            state.searchPayload.search_filters.page = 1;
            state.searchPayload.search_filters[key].splice(index, 1);
            if (!preventSearch) {
                state.emitSearchFilterChanged -= 1;
            }
        }
    },

    STORE_SEARCH_TAG(state, { key, value }) {
        const index = state.searchPayload.search_filters[key].findIndex(o => o === value);
        if (index < 0) {
            state.searchPayload.search_filters.page = 1;
            state.searchPayload.search_filters[key].push(value);
            state.emitSearchFilterChanged += 1;
        }
    },

    DELETE_SEARCH_TAG(state, { key, value }) {
        const index = state.searchPayload.search_filters[key].findIndex(o => o === value);
        if (index >= 0) {
            state.searchPayload.search_filters[key].splice(index, 1);
        }
    },

    INIT_SEARCH_PAYLOAD(state) {
        state.searchPayload.search_filters = JSON.parse(JSON.stringify(state.initSearchCriteria));
    },

    CLEAR_SEARCH_FILTER(state, key) {
        state.searchPayload.search_filters[key] = state.initSearchCriteria[key];
        state.emitSearchFilterChanged += 1;
    },

    DELETE_CATEGORY(state, { id }) {
        const index = state.searchPayload.search_filters.categories.findIndex(o => o.id === id);
        if (index >= 0) {
            state.searchPayload.search_filters.categories.splice(index, 1);
        }
    },

    DELETE_OCCUPATION(state, { occupation }) {
        const categoryIndex = state.searchPayload.search_filters.categories.findIndex(o => parseInt(o.id) === parseInt(occupation.category_id));
        if (categoryIndex >= 0) {
            const occupationIndex = state.searchPayload.search_filters.categories[categoryIndex].occupations.findIndex(o => parseInt(o.id) === parseInt(occupation.id));
            if (occupationIndex >= 0) {
                state.searchPayload.search_filters.categories[categoryIndex].occupations.splice(occupationIndex, 1);
            }
        }
    },

    TOGGLE_JOB_VISIBILITY(state, data) {
        const jobs = state.searchPayload.search_results.jobs;
        this._vm.$set(jobs[data.jobIndex], 'is_hidden', !jobs[data.jobIndex].is_hidden);
    },

    SET_SEARCH_PAGE_TEMPLATE(state, data) {
        state.searchPageTemplate = data;
    },

    SET_SEARCH_PAGE_META_TITLE(state, data) {
        state.pageMetaData.h1 = data;
    },
};
