
    import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
    import {
        faCheckCircle as farCheckCircle,
    } from '@fortawesome/pro-regular-svg-icons/faCheckCircle';
    import {
        faBookmark as farBookmark,
    } from '@fortawesome/pro-regular-svg-icons/faBookmark';
    import {
        faStar as farStar,
    } from '@fortawesome/pro-regular-svg-icons/faStar';
    import {
        faCompass as farCompass,
    } from '@fortawesome/pro-regular-svg-icons/faCompass';
    import {
        faEyeSlash as farEyeSlash,
    } from '@fortawesome/pro-regular-svg-icons/faEyeSlash';
    import {
        faCog as farCog,
    } from '@fortawesome/pro-regular-svg-icons/faCog';

    export default {
        name: 'SidebarMenuMini',

        components: {
            FontAwesomeIcon,
        },

        data() {
            return {
                icons: {
                    farCheckCircle,
                    farBookmark,
                    farStar,
                    farCompass,
                    farEyeSlash,
                    farCog,
                },
            };
        },

        computed: {
            menu() {
                return [
                    {
                        label: 'Discover',
                        icon: this.icons.farCompass,
                        number: 0,
                        link: { name: 'discover' },
                        is_active: this.$route.path === '/discover',
                    },
                    {
                        label: 'Saved searches',
                        icon: this.icons.farBookmark,
                        number: 0,
                        link: { name: 'my-searches' },
                        is_active: this.$route.path === '/my-searches',
                    },
                    {
                        label: 'Saved jobs',
                        icon: this.icons.farStar,
                        number: 0,
                        link: { name: 'my-activity-slug', params: { slug: 'saved-jobs' } },
                        is_active: this.$route.path === '/my-activity/saved-jobs',
                    },
                    {
                        label: 'Applied',
                        icon: this.icons.farCheckCircle,
                        number: 0,
                        link: { name: 'my-activity-slug', params: { slug: 'applied' } },
                        is_active: this.$route.path === '/my-activity/applied',
                    },
                    {
                        label: 'Hidden jobs',
                        icon: this.icons.farEyeSlash,
                        number: 0,
                        link: { name: 'my-activity-slug', params: { slug: 'hidden-jobs' } },
                        is_active: this.$route.path === '/my-activity/hidden-jobs',
                    },
                    {
                        label: 'Settings',
                        icon: this.icons.farCog,
                        number: 0,
                        link: { name: 'settings' },
                        is_active: this.$route.path === '/settings',
                        class: 'mt-auto mb-3',
                    },
                ];
            },
        },

        methods: {
        },
    };
