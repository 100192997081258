export default {
    STORE_APPLIED_JOB(state, data) {
        state.appliedJobs = data;
    },

    PUT_APPLIED_JOB(state, data) {
        const index = state.appliedJobs.findIndex(o => o === data.job_uuid);
        if (index >= 0) {
            state.appliedJobs.splice(index, 1, data);
        } else {
            state.appliedJobs.unshift(data);
        }
    },
};
