
    import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
    import {
        faChevronRight as farChevronRight,
    } from '@fortawesome/pro-regular-svg-icons/faChevronRight';

    export default {
        name: 'ResumeQualityFeedback100',
        components: {
            FontAwesomeIcon,
        },

        data() {
            return {
                icons: {
                    farChevronRight,
                },
            };
        },

        methods: {},
    };
