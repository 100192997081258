export default {
    getJobPreview(context, jobUuid) {
        const url = `v1/jobs-preview-flat/${jobUuid}`;
        return this.$axios.$get(url).then((response) => {
            context.commit('STORE_JOB', response.data);
        });
    },

    getJob(context, { jobUuid, createToken }) {
        const url = `v1/jobs/${jobUuid}?create_token=${createToken ? 1 : 0}`;
        return this.$axios.$get(url).then((response) => {
            context.commit('STORE_JOB', response.data);
            return response;
        });
    },

    getArchivedFlatJob(context, jobUuid) {
        const url = `v1/jobs-archived-flat/${jobUuid}`;
        return this.$axios.$get(url);
    },

    getJobApplyUrl(context, jobUuid) {
        const url = `v1/jobs-apply-url/${jobUuid}`;
        return this.$axios.$get(url);
    },

    searchJobs(context, params) {
        let url = 'v1/search-job';
        if (params) {
            const queryString = Object.keys(params).map(key => `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`).join('&');

            if (queryString) {
                url += `?${queryString}`;
            }
        }
        return this.$axios.$get(url);
    },

    searchJobsByFilter(context, data) {
        const url = 'v1/search-job';
        return this.$axios.$post(url, data);
    },

    getRelatedSearch(context, data) {
        const url = 'v1/related-search';
        return this.$axios.$post(url, data);
    },

    constructFilters(context, data) {
        const url = 'v1/search/construct-filters';
        return this.$axios.$post(url, data);
    },

    getJobTitleSuggestion(context, keyword) {
        const url = `v1/job-title?keyword=${keyword}`;
        return this.$axios.$get(url);
    },
};
